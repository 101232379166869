import React, { HTMLAttributes } from 'react'

import styled, { css } from 'styled-components'

export interface IParagraphProps extends HTMLAttributes<HTMLParagraphElement> {}
export const Paragraph: React.FC<IParagraphProps> = props => <p {...props} />

export interface ISpanProps extends HTMLAttributes<HTMLSpanElement> {}
export const Span: React.FC<ISpanProps> = props => <span {...props} />

export interface IDivProps extends HTMLAttributes<HTMLDivElement> {}
export const Div: React.FC<IDivProps> = props => <div {...props} />

export const ListIcon = styled.div<{ controlRight?: boolean }>`
  display: inline-block;
  position: absolute;
  color: rgba(0, 0, 0, 0.3);
  font-size: 20px;
  line-height: 20px;
  ${({ controlRight }) =>
    controlRight
      ? css`
          right: -3px;
        `
      : css`
          left: -3px;
        `};

  top: 0px;
  transform: translateY(-50%);

  @media (min-width: 500px) {
    display: none;
  }
  * {
    font-size: 20px;
    line-height: 20px;
  }
`

export const Icon = styled.span`
  color: rgba(0, 0, 0, 0.7);
  margin-right: 4px;
  cursor: pointer;
`

export const EditableArea = styled.div<{ editing?: boolean }>`
  position: relative;
  display: inline-block;
  border: 0;
  min-width: 32px;
  /* min-height: 32px; */

  background-color: ${({ editing }) => (editing ? 'rgba(0,0,0,0.1)' : 'transparent')};
  &:hover {
    ${ListIcon} {
      display: inline-block;
    }
  }
`
