import React, { useMemo } from 'react'
import { BsCheck } from 'react-icons/bs'
import { CgClose } from 'react-icons/cg'
import { MdBlock } from 'react-icons/md'

import { useAppTheme } from '../shared/web/src'
import { ChipItem, ChipContainer, ChipLabel, ChipButton } from './styles'
export { ChipListContainer } from './styles'

const matches = ['null', 'yes', 'no']

export type ThreeWayChipProps = {
  size?: number
  label: string
  value?: 'null' | 'yes' | 'no'
  colors?: [string, string, string]
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
export const ThreeWayChip: React.FC<ThreeWayChipProps> = ({ label, value, colors = [], onClick }) => {
  const { theme } = useAppTheme()

  const backgroundColors = useMemo(() => {
    const defaultColors = [theme.colors.textDark, theme.colors.primary, theme.colors.contrast]
    const colorList = defaultColors.map((c, i) => {
      return colors[i] || defaultColors[i] || theme.colors.textDark
    })
    return colorList
  }, [colors, theme])

  const renderIcon = () => {
    if (value === 'no') return <MdBlock />
    if (value === 'yes') return <BsCheck />
    return <CgClose />
  }

  return (
    <ChipItem bgColor={backgroundColors[matches.findIndex(f => f === value)]} txtColor={'#FFF'}>
      <ChipContainer>
        <ChipLabel>{label}</ChipLabel>
        <ChipButton onClick={onClick} value={value}>
          {renderIcon()}
        </ChipButton>
      </ChipContainer>
    </ChipItem>
  )
}
