import React, { useCallback, useState, useRef, useEffect } from 'react'
import { CgClose } from 'react-icons/cg'
import { MdAdd } from 'react-icons/md'

import styled from 'styled-components'

import { DeviceTypeId } from '~/services/Api/devices'
import { EventTypeId, eventTypes } from '~/services/Api/events'

import { useFilter } from '../Filter/FilterProvider'
import DateTimePicker from '../Forms/DateTimePicker'
import { SimpleChip } from '../Forms/SimpleChip'
import { EventFilterDevices } from './EventFilterDevices'

const DateContainer = styled.div`
  width: 100%;
  padding: 10px;
  max-width: 600px;
  display: block;
`

export interface IFilterEvent {
  eventType?: number
  deviceType?: number
  startDate?: Date
  endDate?: Date
}

interface Props {
  deviceType?: DeviceTypeId
}

export const EventFilter: React.FC<Props> = () => {
  const { updateFilter, filter } = useFilter<IFilterEvent>()
  const [eventType, setEventType] = useState(filter?.eventType)
  const ref = useRef(0)

  const handleDateChange = useCallback(
    (date: string, field?: string) => {
      if (field && date) {
        const data = {}
        data[field] = date
        updateFilter(data)
      }
    },
    [updateFilter]
  )

  const handleEventChange = useCallback(
    (event?: number) => {
      const data: any = { eventType: event }
      if (event !== EventTypeId.DEVICE && filter.deviceType) data.deviceType = undefined

      setEventType(event)
      updateFilter(data)
    },
    [updateFilter, filter]
  )

  useEffect(() => {
    if (ref.current < 1) handleEventChange(eventType)
    ref.current++
  }, [eventType, handleEventChange])

  const chipEvents = [0, 3].map(id => eventTypes.find(e => e.id === id))

  return (
    <>
      <DateContainer>
        <DateTimePicker name="startDate" onChange={handleDateChange} label="Data inicial" />
        <DateTimePicker name="endDate" onChange={handleDateChange} label="Data final" />
      </DateContainer>
      <div>
        {chipEvents.map(chip => {
          return (
            <SimpleChip
              key={`chip-${chip.id}`}
              title={chip.label}
              value={chip.id}
              actived={eventType === chip.id}
              onChange={value => handleEventChange(value)}
              Icon={<MdAdd />}
              CloseIcon={<CgClose />}
            />
          )
        })}
      </div>
      {eventType === EventTypeId.DEVICE ? <EventFilterDevices /> : null}
    </>
  )
}
