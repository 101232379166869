import React, { useState, useCallback } from 'react'

import { DateTimePicker as MuiDatePicker, DateTimePickerProps } from '@mui/lab'
import { TextField } from '@mui/material'
import { formatISO } from 'date-fns'

interface IDateFilterProps extends Omit<DateTimePickerProps<Date>, 'value' | 'renderInput' | 'onChange'> {
  initialDate?: Date
  name?: string
  onChange: (date: string, name?: string) => void
}
const DateTimePicker: React.FC<IDateFilterProps> = ({
  label,
  initialDate = null,
  onChange,
  minDate,
  disableFuture = true,
  clearable = true,
  loading,
  name,
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(initialDate)

  const handleChange = useCallback((date: Date) => {
    if (date) {
      setSelectedDate(date)
    }
  }, [])

  const handleAccept = useCallback(
    (date: Date) => {
      if (date) {
        const formattedDate = formatISO(date)
        if (formattedDate) onChange(formattedDate, name)
      }
    },
    [onChange, name]
  )

  return (
    <MuiDatePicker
      minDate={minDate}
      label={label}
      clearable={clearable}
      value={selectedDate}
      loading={loading}
      renderInput={params => <TextField {...params} color="primary" style={{ margin: 4 }} />}
      disableFuture={disableFuture}
      {...rest}
      onChange={handleChange}
      onAccept={handleAccept}
    />
  )
}

export default DateTimePicker
