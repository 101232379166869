import React from 'react'

import { useAppTheme, VariantColorsTypes } from '@shared-core/index'

import { Button, Field } from './styles'

export type InputButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  fullWidth?: boolean
  isText?: boolean
  bold?: boolean
  themeColor?: VariantColorsTypes
  hoverColor?: string
  title?: string
  text?: string
}
export const InputButton: React.FC<InputButtonProps> = ({
  fullWidth,
  isText,
  bold,
  themeColor = 'white',
  hoverColor,
  children,
  title,
  text,
  disabled,
  ...rest
}) => {
  const { theme, matchingBackgroudText } = useAppTheme()
  const color = matchingBackgroudText(themeColor)
  const bgColor = theme.colors[themeColor]

  return (
    <Field>
      <Button
        grow={(fullWidth && 1) || undefined}
        isText={!!isText}
        bold={!!(title || bold)}
        color={color}
        bgColor={bgColor}
        bgHover={hoverColor}
        disabled={!!disabled}
        {...rest}
      >
        {title || text || children}
      </Button>
    </Field>
  )
}
