import React from 'react'

import styled from 'styled-components'

const Label = styled.label<{ size: number; focused?: boolean }>`
  display: block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  border-width: 2px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.rounded}px;
  background-color: ${({ theme, focused }) => (focused ? theme.colors.contrast : theme.colors.secondary)};
  border-color: ${({ theme, focused }) => (focused ? theme.colors.contrast : theme.colors.secondary)};
`
export type LabelIconProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  size?: number
  focused?: boolean
}
export const LabelIcon: React.FC<LabelIconProps> = ({ size = 38, focused, ...rest }) => {
  return <Label size={size} focused={!!focused} {...rest} />
}
