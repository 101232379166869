import { DeviceTypeId, deviceTypes } from './Api/devices'
import { EventTypeId, eventTypes } from './Api/events'

export function getEventLabel(id: EventTypeId): string {
  return eventTypes.find(f => f.id === id)?.label
}

export function getDeviceLabel(id: DeviceTypeId): string {
  return deviceTypes.find(f => f.id === id)?.friendly
}
