import React from 'react'
import { BiPlus, BiSearch } from 'react-icons/bi'
// import { FaEdit } from 'react-icons/fa'
// import { FaTrashAlt } from 'react-icons/fa'
import { IoTrash } from 'react-icons/io5'
import { MdFilterList, MdEdit } from 'react-icons/md'

import { Button } from './styles'

export const iconsNames = {
  filter: MdFilterList,
  plus: BiPlus,
  edit: MdEdit,
  trash: IoTrash,
  search: BiSearch
}

export type ToolButtonProps = {
  iconName: keyof typeof iconsNames
  size?: number
  disabled?: boolean
  color?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const ToolButton: React.FC<ToolButtonProps> = ({ iconName, size = 32, disabled, color, onClick }) => {
  const Icon = iconsNames[iconName]
  return (
    <Button size={size} disabled={!!disabled} color={color} onClick={onClick}>
      <Icon size={size} />
    </Button>
  )
}
