import React, { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory } from 'react-router-dom'

import { AlertItem } from '~/components/_admin/AlertList/AlertItem'
import { CircleLoading } from '~/components/CircleLoading'
import { Container } from '~/components/layout/Container'
import { PageTitle } from '~/components/PageTitle'
import { SearchBar } from '~/components/SearchBar'
import { Paragraph } from '~/components/shared/web/src/styled'
import { ToolBar } from '~/components/ToolBar'
import { ToolButton } from '~/components/ToolBar/ToolButton'
import { ToolSvgButton } from '~/components/ToolBar/ToolSvgButton'

import { useIsMounted } from '~/hooks/useIsMounted'
import type { ParamsPagination } from '~/services/Api/'
import {
  ParamsPaginateAlertItems,
  paginateAlertItems,
  IAlertItems,
  IFilterAlertItems
} from '~/services/Api/alerts-items'

const pageSize = 20

export const Alerts: React.FC = () => {
  const isMounted = useIsMounted()
  const { push } = useHistory()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IAlertItems[]>([])
  const [pagination, setPagination] = useState<ParamsPagination>({ page: 1, size: pageSize })
  const [hasMore, setHasMore] = useState(true)
  const [filter, setFilter] = useState<IFilterAlertItems>({ search: '' })
  const [hasError, setHasError] = useState(false)

  const fetchData = useCallback(
    async (paginate: ParamsPaginateAlertItems = {}) => {
      const { page = 1, size, ...rest } = paginate
      const query: ParamsPagination = { page, size: size || pageSize, orderBy: 'id', order: 'asc', ...rest }
      if (query.page === 1) setLoading(true)

      const response = await paginateAlertItems(query)
      if (isMounted.current) {
        setLoading(false)
        if (response?.success) {
          setData(old => [...old, ...response?.data])
          setPagination({ page: response?.page })
          setHasMore(!!(response?.page < response?.pages))
        } else {
          setHasError(true)
        }
      }
    },
    [isMounted]
  )

  const resetFilter = useCallback(() => {
    const newFilter: Partial<IFilterAlertItems> = { ...filter, search: undefined }
    setFilter(newFilter)
    setPagination(old => ({ ...old, page: 1 }))
    setData([])
    fetchData({ page: 1, ...newFilter })
  }, [filter, fetchData])

  const updateFilter = useCallback(
    (data: Partial<IFilterAlertItems>) => {
      const newFilter = { ...data }
      setFilter(old => ({ ...old, ...newFilter }))
      setPagination(old => ({ ...old, page: 1 }))
      setData([])
      fetchData({ page: 1, ...newFilter })
    },
    [fetchData]
  )

  const handleSearchChange = useCallback((search?: string) => updateFilter({ search }), [updateFilter])

  const handleFetchMoreData = useCallback(() => {
    fetchData({ page: (pagination?.page || 1) + 1, ...filter })
  }, [fetchData, pagination, filter])

  const renderMessage = useCallback((text: string) => {
    return (
      <Paragraph align="center" themeColor="textDark">
        {text}
      </Paragraph>
    )
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <div>
      <Container horizontalSpaced>
        <PageTitle spotlight={'Relatórios'} title="de alertas agendados">
          <ToolBar justify="flex-end">
            <ToolSvgButton iconName="module" onClick={() => push('/modules')} />
            <ToolButton iconName="search" onClick={() => push('/search-serial')} />
          </ToolBar>
        </PageTitle>
        <Container verticalSpaced>
          <SearchBar
            onChangeText={handleSearchChange}
            placeholder="busca por alarmes"
            hasError={hasError}
            messageError={'* pesquisa inválida'}
          />
        </Container>
      </Container>
      <Container horizontalSpaced>
        <InfiniteScroll
          dataLength={data.length}
          scrollableTarget="layout-container"
          hasMore={!!hasMore}
          next={handleFetchMoreData}
          loader={
            <Container>
              {pagination.page > 1 ? <CircleLoading size={20} relative backgroundColor="#0000" /> : null}
            </Container>
          }
          endMessage={renderMessage('FIM')}
        >
          {data?.map(item => {
            return <AlertItem key={item.id} {...item} />
          })}
        </InfiniteScroll>
      </Container>
      {loading ? <CircleLoading /> : null}
    </div>
  )
}
