import styled, { css } from 'styled-components'

import { FlexJustify } from '../../shared/core/src'

export const ItemIcon = styled.div`
  width: 44px;
`

export const ItemModule = styled.span`
  display: block;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary};
`
export const ItemLabel = styled.span`
  position: relative;
  line-height: 18px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textDark};
`

export const ItemFlex = styled.div<{ grow?: boolean }>`
  display: block;
  padding-left: ${({ theme }) => theme.spacing.m}px;
  ${({ grow }) =>
    grow
      ? css`
          flex: 1;
        `
      : css``}
`

export const UserName = styled.span`
  display: block;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.textDark};
  text-align: right;
`
export const Flex = styled.div<{ justify?: FlexJustify }>`
  display: flex;
  justify-content: ${({ justify = 'flex-start' }) => justify};
  align-items: center;
  flex-flow: row nowrap;
`

export const ItemContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  margin-bottom: ${({ theme }) => theme.spacing.l}px;
  border-radius: ${({ theme }) => theme.rounded}px;
  padding: ${({ theme }) => theme.spacing.m}px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.3);

  ${ItemLabel}, .editableLabel {
    display: block;
    position: relative;
    line-height: 18px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.textDark};
  }
`

export const AnimeDivider = styled.div<{ expanded?: boolean }>`
  height: 2px;
  position: relative;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: ${({ expanded }) => (expanded ? '100%' : '0%')};
    height: 1px;
    background-color: ${({ theme }) => theme.colors.border};
    transition: all linear 0.3s 0.1s;
  }
`

export const TabPanelContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.l}px;
`

export const ExpandedContainer = styled.div`
  display: block;
  position: relative;
  min-height: 100px;
`

export const LiItem = styled.li`
  font-size: 12px;
  font-family: 'Roboto Lt';
  span {
    font-size: 10px;
    padding-left: 10px;
    font-family: 'Roboto Lt';
  }
`
