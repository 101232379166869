import React from 'react'

import styled from 'styled-components'

import logo from '~/assets/images/logo-des.svg'

const Img = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;
`

export const Logo: React.FC = () => {
  return <Img src={logo} alt="" />
}
