import { isDefined } from './variables'

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item?: any): boolean {
  return item && typeof item === 'object' && !Array.isArray(item) && !(item instanceof Date)
}

export function removeInvalidValues<T = unknown>(obj: Record<string, any>): T {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (isDefined(value)) acc[key] = value
    return acc
  }, {} as T)
}
