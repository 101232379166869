import React from 'react'

import { CustomIconSvgProps } from '~/components/icons/types'

import { DeviceTypeId } from '~/services/Api/devices'

import { IconDeviceSvg } from './icons/IconDeviceSvg'
import { IconTagSvg } from './icons/IconTagSvg'

const icons = [
  { id: DeviceTypeId.RF, name: 'rx', icon: IconDeviceSvg },
  { id: DeviceTypeId.TA, name: 'ta', icon: IconTagSvg }
] as const

type LocalIcon = typeof icons[number]

type Props = CustomIconSvgProps & {
  deviceTypeId?: DeviceTypeId
  iconName?: LocalIcon['name']
}

export const DeviceIcon: React.FC<Props> = ({ deviceTypeId, iconName, ...rest }) => {
  const Icon = deviceTypeId
    ? icons.find(icon => icon.id === deviceTypeId)?.icon
    : icons.find(icon => icon.name === iconName)?.icon

  return <>{Icon ? <Icon {...rest} /> : null}</>
}
