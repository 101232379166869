import { createGlobalStyle } from 'styled-components'

import { roboto } from './fonts'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  @font-face {
    font-family: "Roboto Lt";
    src: local("Roboto Lt"),
    url('${roboto.RobotoLtWOFF}') format('woff'),
    url('${roboto.RobotoLtTTF}') format('truetype'),
    url('${roboto.RobotoLtEOT}') format('embedded-opentype');

    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  body {
    font-family: "Roboto Lt";
  }
`
