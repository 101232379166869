import React from 'react'
import { BiAlarm, BiSearch, BiLogOutCircle } from 'react-icons/bi'
import { useHistory } from 'react-router-dom'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { IconModuleSvg } from '~/components/icons/IconModuleSvg'

import { useAppAuth } from '../../hooks/useAppAuth'
import { privateRouteList, IAppRoute } from '../../routes/private'

export const iconsNames = {
  modules: IconModuleSvg,
  alerts: BiAlarm,
  'search-serial': BiSearch,
  logout: BiLogOutCircle
}

type ItemMenu = Partial<IAppRoute> & { icon?: typeof BiAlarm | typeof IconModuleSvg }
type Props = {
  toggleMenu: (isOpen?: boolean) => void
}
export const Navigation: React.FC<Props> = ({ toggleMenu }) => {
  const { logout } = useAppAuth()
  const { push } = useHistory()

  const handleClose = () => {
    if (toggleMenu) toggleMenu(false)
  }

  const goTo = (path: string) => {
    return () => {
      handleClose()
      push(path)
    }
  }

  const list: ItemMenu[] = privateRouteList
    .filter(({ title }) => !!title)
    .map(f => {
      const [, icon] = Object.entries(iconsNames).find(([k]) => {
        return f.path.split('/').includes(k)
      })
      return { ...f, icon }
    })

  return (
    <List component="nav" aria-label="main mailbox folders">
      {list.map(itemMenu => {
        const Icon = itemMenu?.icon
        return (
          <div key={itemMenu.path}>
            <ListItemButton onClick={goTo(itemMenu.path)} divider>
              <ListItemIcon>{Icon ? <Icon color="#FFF" size={24} /> : null}</ListItemIcon>
              <ListItemText primary={itemMenu.title} />
            </ListItemButton>
          </div>
        )
      })}
      <div>
        <ListItemButton onClick={logout} divider>
          <ListItemIcon>
            <BiLogOutCircle color="#FFF" size={24} />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItemButton>
      </div>
    </List>
  )
}
