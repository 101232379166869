import React, { useCallback, useMemo, useRef, useState, MutableRefObject } from 'react'
import ContentEditable from 'react-contenteditable'
import { FaEdit, FaSave } from 'react-icons/fa'
import { TiArrowBack } from 'react-icons/ti'

import { isDefined } from '~/helpers/variables'

import { EditableArea, ListIcon, Icon } from './styles'

export type EditableProps = {
  editActive?: boolean
  tagName?: string
  onEditChange?: (value?: string) => void
  onEdit?: () => void
  onToggle?: (editing?: boolean) => void
  onSave?: (value?: string) => Promise<boolean | void>
  innerRef?: MutableRefObject<HTMLElement>
  editing?: boolean
  html?: string
  hidden?: boolean
  controlRight?: boolean
  className?: string
}

const Editable: React.FC<EditableProps> = ({
  innerRef,
  editing,
  html,
  tagName,
  onToggle,
  onEditChange,
  onSave,
  children,
  hidden,
  controlRight,
  className
}) => {
  const [inSet, setInSet] = useState(!!editing)

  const content = useMemo(() => {
    return typeof html === 'string' ? html : JSON.stringify(html)
  }, [html])

  const htmlRef = useRef(content)

  const [newValue, setNewValue] = useState(html)

  const toggle = useCallback(
    (sinal?: boolean) => {
      if (isDefined(sinal)) {
        setInSet(!!sinal)
        if (onToggle && typeof onToggle === 'function') {
          onToggle(!!sinal)
        }
      } else {
        setInSet(old => !old)
      }
    },
    [onToggle]
  )

  const handleBack = useCallback(() => {
    if (htmlRef) setNewValue(htmlRef.current)
    toggle(false)
  }, [toggle])

  const handleSave = useCallback(async () => {
    if (onSave && typeof onSave === 'function') {
      onSave(newValue)
      htmlRef.current = newValue
    }
    toggle(false)
  }, [onSave, newValue, toggle])

  const handleChange = useCallback(
    evt => {
      const value = evt && evt.target && evt.target.value
      setNewValue(value)
      if (onEditChange && typeof onEditChange === 'function') onEditChange(value)
    },
    [onEditChange]
  )

  const handleEdit = useCallback(() => {
    toggle(true)
  }, [toggle])

  const handleEsc: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (event?.key === 'Escape') {
        event.preventDefault()
        toggle(false)
        return false
      }
    },
    [toggle]
  )

  return (
    <EditableArea onClick={e => e.stopPropagation()} editing={!!inSet}>
      {children ? (
        <>{children}</>
      ) : (
        <ContentEditable
          innerRef={innerRef}
          onChange={handleChange}
          html={newValue}
          disabled={!inSet}
          tagName={tagName}
          onDoubleClick={() => toggle(true)}
          onKeyUp={handleEsc}
          className={className}
        />
      )}
      {!hidden ? (
        <ListIcon controlRight={controlRight}>
          {inSet ? (
            <>
              <Icon>{onSave ? <FaSave onClick={handleSave} /> : null}</Icon>
              <Icon>
                <TiArrowBack onClick={handleBack} />
              </Icon>
            </>
          ) : (
            <Icon>
              <FaEdit onClick={handleEdit} />
            </Icon>
          )}
        </ListIcon>
      ) : null}
    </EditableArea>
  )
}
export default Editable
