import styled from 'styled-components'

import { darken } from '~/helpers/colors'

export const ChipListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const ChipContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  align-content: center;
  border: 0;
  border-radius: 12px;
  padding: ${({ theme }) => theme.spacing.s}px;
  padding-left: ${({ theme }) => theme.spacing.l}px;
`

export const ChipLabel = styled.span`
  display: block;
  max-width: 100%;
  margin-right: ${({ theme }) => theme.spacing.l}px;
`

export const ChipButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 28px;
  height: 28px;
  font-size: 22px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
`

export const ChipItem = styled.div<{ bgColor: string; txtColor: string }>`
  border: 0;
  margin-right: ${({ theme }) => theme.spacing.m}px;
  margin-bottom: ${({ theme }) => theme.spacing.m}px;
  ${ChipContainer} {
    background-color: ${({ bgColor }) => bgColor};
    color: ${({ txtColor }) => txtColor};
  }
  ${ChipButton} {
    background-color: ${({ bgColor }) => darken(bgColor)};
    color: ${({ txtColor }) => txtColor};
  }
`
