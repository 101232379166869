import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton, IconButtonProps } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { styled as styledMUI } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
import { object } from 'dot-object'

import { withEditable } from '~/components/EditableElement'
import type { EditableProps } from '~/components/EditableElement/Editable'
import { IconEvent } from '~/components/icons/IconEvent'

import { IAlertItems, IUpdateItemItemsParams, updateAlertItems } from '~/services/Api/alerts-items'

import { useIsMounted } from '../../../hooks/useIsMounted'
import { AlertItemTabs } from './AlertItemTabs'
import { AnimeDivider, Flex, ItemContainer, ItemFlex, ItemIcon, ItemLabel, ItemModule, UserName } from './styles'

const TestSpan = withEditable(ItemLabel)

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styledMUI((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props
  return <IconButton {...other} size="small" />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

type AlertItemProps = IAlertItems & {}
export const AlertItem: React.FC<AlertItemProps> = ({ alert, guarita, type, user, id }) => {
  const isMounted = useIsMounted()
  const [saving, setSaving] = useState(false)
  const [checked, setChecked] = useState(!!alert?.actived)
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleSave = useCallback(
    async (data: IUpdateItemItemsParams) => {
      setSaving(true)
      const response = await updateAlertItems(id, data)
      if (response.success) toast.success('Alerta salvo com sucesso!')
      else toast.error('Erro ao salvar alerta')
      if (isMounted.current) {
        setSaving(false)
      }
      return !!response?.success
    },
    [id, isMounted]
  )

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setChecked(c => !c)
      handleSave({ alert: { actived: !!checked } })
    },
    [handleSave]
  )

  const saveLabel = (prop: string): EditableProps['onSave'] => {
    return async (value?: string) => {
      if (value) {
        const data = object({ [prop]: value })
        handleSave(data)
      }
    }
  }

  return (
    <ItemContainer>
      <Flex>
        <ItemIcon>
          <Tooltip title={`ID: ${id}`} placement="top">
            <span>
              <IconEvent eventType={type} size={44} />
            </span>
          </Tooltip>
        </ItemIcon>
        <ItemFlex grow>
          <ItemModule>{guarita.name}</ItemModule>
          <TestSpan className="editableLabel" tagName="span" controlRight onSave={saveLabel('alert.label')}>
            {alert.label}
          </TestSpan>
        </ItemFlex>
        <ItemFlex>
          <UserName>{user?.name}</UserName>
          <Flex justify="flex-end">
            <Switch size="small" checked={!!checked} onChange={handleCheck} />
          </Flex>
        </ItemFlex>
      </Flex>
      <Flex>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          disabled={!!saving}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Flex>
      <Collapse in={expanded} timeout="auto">
        <AnimeDivider expanded={expanded} />
        {expanded ? <AlertItemTabs alertItemId={id} onSave={handleSave} /> : null}
      </Collapse>
    </ItemContainer>
  )
}
