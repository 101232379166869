import React, { useCallback, useMemo } from 'react'
import { IoArrowBack } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'

import { darken } from '~/helpers/colors'

import { useAppTheme, VariantColorsTypes } from './shared/core/src'

const BackContainer = styled.div<{ bgColor: string; textColor?: string }>`
  margin-right: ${({ theme }) => theme.spacing.m}px;
  border: 0;
  button {
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: ${({ textColor = 'currentColor' }) => textColor};
    width: 22px;
    height: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    &:hover {
      background-color: ${({ bgColor }) => darken(bgColor)};
    }
  }
`

const PageTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 100%;
  /* border: 1px dashed #000; */
  padding: 0;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing.l}px;
  padding-top: ${({ theme }) => theme.spacing.l}px;
`

const Title = styled.div<{ color: string }>`
  flex: 1;
  h2 {
    color: ${({ theme }) => theme.colors.textDark};
    font-weight: normal;
    font-size: 18px;
  }

  span {
    color: ${({ color }) => color};
  }
`
const Tools = styled.div``

export type PageTitleProps = {
  title: string
  spotlight?: string
  themeColor?: VariantColorsTypes
  back?: boolean
}

export const PageTitle: React.FC<PageTitleProps> = ({ children, title, spotlight, themeColor = 'primary', back }) => {
  const { theme, matchingBackgroudText } = useAppTheme()
  const { go } = useHistory()

  const textColor = useMemo(() => matchingBackgroudText(themeColor), [matchingBackgroudText, themeColor])
  const handleBack = useCallback(() => go(-1), [go])

  return (
    <PageTitleContainer>
      {back ? (
        <BackContainer bgColor={theme.colors[themeColor]} textColor={textColor}>
          <button type="button" onClick={handleBack}>
            <IoArrowBack size={18} />
          </button>
        </BackContainer>
      ) : null}
      <Title color={theme.colors[themeColor]}>
        <h2>
          {spotlight ? <span>{spotlight} </span> : null}
          {title}
        </h2>
      </Title>
      <Tools>{children}</Tools>
    </PageTitleContainer>
  )
}
