import styled, { css } from 'styled-components'

import { alpha, darken } from '~/helpers/colors'

const inputBase = css`
  max-width: 100%;
  display: block;
  border-style: solid;
  border-width: 2px;
  transition: all ease-in-out 0.3s;
`

export const Input = styled.input<{ size?: number; error?: string }>`
  ${inputBase}
  height: ${({ size = 38 }) => size}px;
  border-color: ${({ theme, error }) => (error ? theme.colors.contrast : theme.colors.white)};
  border-radius: ${({ theme }) => theme.rounded}px;
  padding-left: ${({ theme }) => theme.spacing.l}px;
  padding-right: ${({ theme }) => theme.spacing.l}px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  outline: none;
  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`

export const Field = styled.div<{ size?: number; grow?: number }>`
  max-width: 100%;
  display: block;
  border: 0;

  ${({ grow }) =>
    grow
      ? css`
          flex: ${grow};
          width: 100%;
        `
      : null};

  ${Input} {
    flex: 1;
    width: 100%;
    height: ${({ size = 38 }) => size}px;
  }
`

export const Button = styled.button<{
  size?: number
  grow?: number
  isText?: boolean
  bold?: boolean
  bgColor: string
  color: string
  bgHover?: string
  disabled?: boolean
}>`
  ${inputBase}
  cursor: pointer;
  height: ${({ size = 38 }) => size}px;
  padding: auto ${({ theme }) => theme.spacing.l}px;
  border-radius: ${({ theme }) => theme.rounded}px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};

  color: ${({ color }) => color};
  border-color: ${({ bgColor }) => bgColor};
  background-color: ${({ bgColor }) => bgColor};

  &:hover {
    background-color: ${({ bgColor, bgHover }) => bgHover || darken(bgColor, 0.7)};
  }

  ${({ grow }) =>
    grow
      ? css`
          width: 100%;
        `
      : css``};

  ${({ disabled }) =>
    disabled
      ? css`
          width: 100%;
          filter: grayscale(100%);
        `
      : css``};

  ${({ isText, bgColor, bgHover }) =>
    isText
      ? css`
          background-color: transparent;
          color: ${bgColor};
          &:hover {
            background-color: ${bgHover || alpha(bgColor, 0.1)};
          }
        `
      : css``}

  ${Input} {
    flex: 1;
    width: 100%;
    height: ${({ size = 38 }) => size}px;
  }
`
