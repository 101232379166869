import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
  readonly loading: boolean
  readonly signed: boolean
  readonly level?: number
  readonly token?: string | null
  readonly refreshToken?: string | null
  readonly error?: string | null
}

const initialState: AuthState = {
  loading: false,
  signed: false,
  level: 0,
  token: null,
  refreshToken: null,
  error: null
}

export const slice = createSlice({
  name: '@auth',
  initialState,
  reducers: {
    loadRequest: state => {
      state.loading = true
    },
    loadSuccess(state, { payload }: PayloadAction<Partial<AuthState>>) {
      state.loading = false
      state.token = payload?.token
      state.refreshToken = payload.refreshToken
      state.signed = !!(payload.token && payload.refreshToken)
      state.level = payload?.level || 0
      state.error = null
    },
    loadCheckRequest(state) {
      state.loading = true
    },
    loadCheckSuccess(state, _data: PayloadAction<Partial<AuthState>>) {
      state.loading = false
      state.signed = true
      state.error = null
    },
    loadFailure(state, { payload }: PayloadAction<AuthState['error']>) {
      state.token = null
      state.refreshToken = null
      state.signed = false
      state.level = 0
      state.error = payload
      state.loading = false
    },
    setTokens(state, { payload }: PayloadAction<Pick<AuthState, 'token' | 'refreshToken' | 'level'>>) {
      state.loading = false
      state.token = payload.token
      state.refreshToken = payload.refreshToken
      if (payload?.level) state.level = payload.level
    }
  }
})

export const { loadFailure, loadRequest, loadSuccess, loadCheckRequest, loadCheckSuccess, setTokens } = slice.actions

export default slice.reducer
