import React from 'react'

import { FeatureContainer } from './styles'

type FeatureListProps = {
  columns?: number
}

export const FeatureList: React.FC<FeatureListProps> = ({ children, columns = 3 }) => {
  const itemWidth = 100 / columns
  return <FeatureContainer itemWidth={`${itemWidth}%`}>{children}</FeatureContainer>
}
