import React from 'react'

import styled from 'styled-components'

import { FlexOne } from '@shared-web/styled'

import { CloudNothingSvg } from '~/components/icons/CloudNothingSvg'
import { Container } from '~/components/layout/Container'
import { DarkLayout } from '~/components/layout/DarkLayout'

const ImageWrapper = styled.div`
  border: 0;
  margin: 0 auto;
  padding: 0;
  display: block;
  svg,
  img {
    margin: 0 auto;
    display: block;
    padding: 0;
  }
  p {
    margin: 0 auto;
    display: block;
    padding: 0;
    text-align: center;
  }
`

export const PageNoFound: React.FC = () => {
  return (
    <DarkLayout>
      <FlexOne grow={3} justify="center" align="center">
        <Container horizontalSpaced>
          <ImageWrapper>
            <CloudNothingSvg size={100} />
            <p>Página não encontrada</p>
          </ImageWrapper>
        </Container>
      </FlexOne>
      <FlexOne grow={1} />
    </DarkLayout>
  )
}
