import React, { useState, useCallback, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { CircleLoading } from '~/components/CircleLoading'
import { Container } from '~/components/layout/Container'
import { ModuleFilter } from '~/components/Modules/ModuleFilter'
import { ModuleItem } from '~/components/Modules/ModuleItem'
import { PageTitle } from '~/components/PageTitle'
import { SearchBar } from '~/components/SearchBar'
import { useAppTheme } from '~/components/shared/web/src'
import { Paragraph } from '~/components/shared/web/src/styled'
import { ToolBar } from '~/components/ToolBar'
import { ToolButton } from '~/components/ToolBar/ToolButton'

import { querystring } from '~/helpers/string'
import { isDefined } from '~/helpers/variables'
import { useIsMounted } from '~/hooks/useIsMounted'
import Api from '~/services/Api'
import type { IResponsePaginate } from '~/services/Api'
import type { IGuarita } from '~/services/Api/guaritas'

const pageSize = 20

interface IFilterModule {
  search?: string
  workoff?: boolean
  online?: boolean
}

type Pagination = {
  page?: number
  size?: number
  orderBy?: string
  order?: string
}
export const Modules: React.FC = () => {
  const { theme } = useAppTheme()
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IGuarita[]>([])
  const [pagination, setPagination] = useState<Pagination>({ page: 1, size: pageSize })
  const [hasMore, setHasMore] = useState(true)
  const [filter, setFilter] = useState<IFilterModule>({ search: '' })
  const [advancedFilter, setAdvancedFilter] = useState(false)

  const fetchData = useCallback(
    async (paginate: Partial<Pagination> & Partial<IFilterModule> = {}) => {
      const { page = 1, size, ...rest } = paginate
      const query: Pagination = { page, size: size || pageSize, orderBy: 'name', order: 'asc', ...rest }
      if (query.page === 1) setLoading(true)

      const url = `/guaritas?${querystring(query)}`
      const response = await Api.getDefault<IResponsePaginate<IGuarita>>(url)
      if (isMounted.current) {
        setLoading(false)
        if (response?.success) {
          setData(old => [...old, ...response.data])
          setPagination({ page: response.page })
          setHasMore(!!(response.page < response.pages))
        }
      }
    },
    [isMounted]
  )

  const resetFilter = useCallback(() => {
    const newFilter: Partial<IFilterModule> = { ...filter, online: undefined, workoff: undefined }
    setFilter(newFilter)
    setPagination(old => ({ ...old, page: 1 }))
    setData([])
    fetchData({ page: 1, ...newFilter })
  }, [filter, fetchData])

  const updateFilter = useCallback(
    (data: Partial<IFilterModule>) => {
      const newFilter = { ...data }
      setFilter(old => ({ ...old, ...newFilter }))
      setPagination(old => ({ ...old, page: 1 }))
      setData([])
      fetchData({ page: 1, ...newFilter })
    },
    [fetchData]
  )

  const handleFilter = useCallback(
    (force?: boolean) => {
      const newAdvFilter = isDefined(force) ? force : !advancedFilter
      setAdvancedFilter(newAdvFilter)
      if (!newAdvFilter) resetFilter()
    },
    [advancedFilter, resetFilter]
  )

  const handleFetchMoreData = useCallback(() => {
    fetchData({ page: (pagination?.page || 1) + 1, ...filter })
  }, [fetchData, pagination, filter])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleSearchChange = useCallback((search?: string) => updateFilter({ search }), [updateFilter])

  const renderMessage = useCallback((text: string) => {
    return (
      <Paragraph align="center" themeColor="textDark">
        {text}
      </Paragraph>
    )
  }, [])

  return (
    <div>
      <Container horizontalSpaced>
        <PageTitle spotlight={'Módulos'} title={'disponíveis'}>
          <ToolBar justify="flex-end">
            <ToolButton
              iconName="filter"
              color={advancedFilter ? theme.colors.secondary : ''}
              onClick={() => handleFilter()}
            />
            <ToolButton iconName="plus" disabled />
          </ToolBar>
        </PageTitle>
      </Container>
      {advancedFilter ? (
        <Container horizontalSpaced>
          <ModuleFilter onFilter={updateFilter} />
        </Container>
      ) : null}

      <Container horizontalSpaced>
        <SearchBar onChangeText={handleSearchChange} placeholder={'pesquisar módulo'} />
      </Container>
      <InfiniteScroll
        dataLength={data.length}
        scrollableTarget="layout-container"
        hasMore={!!hasMore}
        next={handleFetchMoreData}
        loader={
          <Container>
            {pagination.page > 1 ? <CircleLoading size={20} relative backgroundColor="#0000" /> : null}
          </Container>
        }
        endMessage={renderMessage('FIM')}
      >
        {data.map(({ id, name, mac, ping, version, workoff, online }) => {
          return (
            <ModuleItem
              key={`module-item-${id}`}
              id={id}
              name={name}
              mac={mac}
              version={version}
              ping={ping}
              workoff={workoff}
              online={online}
            />
          )
        })}
      </InfiniteScroll>
      {loading ? <CircleLoading /> : null}
    </div>
  )
}
