import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from '~/store'
import { AuthState, loadSuccess } from '~/store/reducers/auth'

type AuthorizePayload = (payload: Partial<AuthState & { level?: number }>) => void
type UseAppSigned = [boolean, number, AuthorizePayload]

export function useAppSigned(): UseAppSigned {
  const signed = useSelector<ApplicationState, boolean>(state => state.auth.signed)
  const signedLevel = useSelector<ApplicationState, number>(state => state.auth.level)
  const dispatch = useDispatch()

  const authorize: AuthorizePayload = useCallback(
    payload => {
      dispatch(loadSuccess(payload))
    },
    [dispatch]
  )

  return [!!signed, signedLevel, authorize]
}
