import styled from 'styled-components'

import { darken } from '~/helpers/colors'

export const ChatTopItem = styled.div`
  display: block;
  margin-right: 10px;

  button {
    display: inline-block;
    background-color: transparent;
    border: 0;
    color: inherit;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
`

export const ChatTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: ${({ theme }) => theme.spacing.m}px;
  ${ChatTopItem}:last-child {
    margin-right: 0;
  }
`

export const Label = styled.span<{ size?: number; color?: string }>`
  display: block;
  font-size: ${({ size = 10 }) => size}px;
  color: ${({ color, theme }) => color || theme.colors.border};
`
export const Value = styled.span<{ size?: number }>`
  font-size: ${({ size = 12 }) => size}px;
`

export const ChartContent = styled.div<{ height?: number }>`
  background-color: ${({ theme }) => darken(theme.colors.primary)};
  height: ${({ height = 100 }) => height}px;
  margin-bottom: ${({ theme }) => theme.spacing.m}px;
`
