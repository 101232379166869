/**
 * @function rangeArray
 * @example
 * rangeArray(1, 5, 1) // [1, 2, 3, 4, 5]
 */
export function rangeArray(init = 1, fim = 10, interval = 1): number[] {
  const result = []
  for (let index = init; index < fim; index += interval) result.push(index)
  return result
}

/**
 * @function medianInArray
 */
export function medianInArray(arr: number[], withZero?: boolean) {
  if (!Array.isArray(arr)) return 0
  const array = withZero ? arr.map(a => a || 0) : arr.filter(a => !!(a && a > 0))
  array.sort((a, b) => a - b)
  const lowMid = Math.floor((array.length - 1) / 2)
  const highMid = Math.ceil((array.length - 1) / 2)
  return (array[lowMid] + array[highMid]) / 2
}

/**
 * @function averageInArray
 */
export function averageInArray(arr: number[], withZero?: boolean) {
  if (!Array.isArray(arr)) return 0
  const array = withZero ? arr.map(a => a || 0) : arr.filter(f => f)
  return array.reduce((a, b) => a + b, 0) / array.length
}

export function compareValues<T = unknown>(key: keyof T, order = 'asc') {
  return function innerSort(a: any, b: any) {
    if (!(key in a) || !(key in b)) return 0
    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return order === 'desc' ? comparison * -1 : comparison
  }
}
