import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { FiMoreVertical } from 'react-icons/fi'

import { Card } from '~/components/Card'
import { ModuleOnlineSvg } from '~/components/icons/ModuleOnlineSvg'

import { round } from '~/helpers/number'
import { useIsMounted } from '~/hooks/useIsMounted'
import Api from '~/services/Api'
import type { IResponseGuaritaSummary } from '~/services/Api/guaritas'

import { Chart } from './Chart'
import { ChatTop, Label, Value, ChatTopItem, ChartContent } from './styles'

type Props = {
  moduleId: number
  online?: boolean
  workoff?: boolean
}

export const BufferedChat: React.FC<Props> = ({ moduleId, online, workoff }) => {
  const isMounted = useIsMounted()
  const [, setLoading] = useState(false)
  const [summary, setSummary] = useState<IResponseGuaritaSummary>()

  const fetchData = useCallback(
    async (lastdays = 31) => {
      setLoading(true)
      const url = `/guaritas/${moduleId}/summary?lastdays=${lastdays}`
      const response = await Api.getDefault<IResponseGuaritaSummary>(url)
      if (isMounted.current) {
        setLoading(false)
        if (response?.success) {
          setSummary(response)
        }
      }
    },
    [isMounted, moduleId]
  )

  const netWorkQuality = useMemo(() => {
    if (workoff) return 'N/A'
    const p = summary?.average?.ping
    if (p && p <= 100) return 'ÓTIMO'
    if (p && p > 700) return 'RUIM'
    return p ? 'BOM' : '--'
  }, [summary, workoff])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Card>
      <ChatTop>
        <ChatTopItem>
          <ModuleOnlineSvg size={32} online={online} workoff={workoff} />
        </ChatTopItem>
        <ChatTopItem>
          <Label>eventos:</Label>
          <Value>{summary?.totalEvents || '--'}</Value>
        </ChatTopItem>
        <ChatTopItem>
          <Label>dispositivos:</Label>
          <Value>{summary?.totalDevices || '--'}</Value>
        </ChatTopItem>
        <ChatTopItem>
          <Label>internet:</Label>
          <Value title={`${round(summary?.average?.ping || 0, 0)} ms`}>{netWorkQuality}</Value>
        </ChatTopItem>
        <ChatTopItem>
          <button type="button" onClick={() => {}}>
            <FiMoreVertical size={32} />
          </button>
        </ChatTopItem>
      </ChatTop>
      <ChartContent>
        <Chart emittedEvents={summary?.emittedEvents || []} />
      </ChartContent>
    </Card>
  )
}
