import React from 'react'

import { CustomIconSvgProps, svgProps } from './types'

type IconDeviceSvgProps = CustomIconSvgProps & {}

export const IconTagSvg: React.FC<IconDeviceSvgProps> = ({ size = 72, color = 'currentColor' }) => {
  return (
    <svg {...svgProps} width={`${size}px`} height={`${size}px`} viewBox="0 0 3.46 3.46">
      <g id="group">
        <rect fill="none" width="3.46" height="3.46" />
        <path
          id="tag"
          fill={color}
          d="M2.69 1.09l-0.33 -0.33c-0.03,-0.03 -0.09,-0.03 -0.13,0l-0.05 0.05c-0.03,0.04 -0.03,0.1 0,0.13l0.33 0.33c0.04,0.04 0.1,0.04 0.14,0l0.04 -0.04c0.04,-0.04 0.04,-0.1 0,-0.14zm0.25 -0.03c-0.18,-0.18 -0.36,-0.36 -0.55,-0.55 -0.06,-0.06 -0.16,-0.04 -0.24,0l-1.26 0.68c-0.02,0.01 -0.11,0.09 -0.13,0.11 -0.38,0.39 -0.38,1.01 0,1.4 0.39,0.38 1.01,0.38 1.4,0 0.03,-0.03 0.09,-0.1 0.11,-0.14l0.67 -1.25c0.05,-0.08 0.07,-0.18 0,-0.25zm-1.07 0.85c0.03,0.02 0.07,0.01 0.09,-0.02 0.02,-0.03 0.01,-0.07 -0.02,-0.09 -0.07,-0.05 -0.16,-0.09 -0.24,-0.12 -0.09,-0.03 -0.18,-0.04 -0.27,-0.04 -0.09,0 -0.18,0.01 -0.27,0.04 -0.08,0.03 -0.17,0.07 -0.24,0.12 -0.03,0.02 -0.04,0.06 -0.02,0.09 0.03,0.03 0.06,0.04 0.09,0.02 0.07,-0.05 0.14,-0.09 0.21,-0.11 0.07,-0.02 0.15,-0.04 0.23,-0.04 0.08,0 0.16,0.02 0.23,0.04 0.07,0.02 0.14,0.06 0.21,0.11zm-0.18 0.28c0.03,0.02 0.07,0.02 0.09,-0.01 0.02,-0.03 0.01,-0.07 -0.02,-0.09 -0.04,-0.04 -0.1,-0.06 -0.16,-0.08 -0.05,-0.02 -0.11,-0.03 -0.17,-0.03 -0.06,0 -0.12,0.01 -0.17,0.03 -0.06,0.02 -0.11,0.04 -0.16,0.08 -0.03,0.02 -0.04,0.06 -0.02,0.09 0.02,0.03 0.06,0.03 0.09,0.01 0.04,-0.03 0.08,-0.05 0.12,-0.06 0.05,-0.02 0.09,-0.02 0.14,-0.02 0.05,0 0.09,0 0.14,0.02 0.04,0.01 0.08,0.03 0.12,0.06zm-0.16 0.2c0,-0.05 -0.04,-0.1 -0.1,-0.1 -0.06,0 -0.1,0.05 -0.1,0.1 0,0.06 0.04,0.1 0.1,0.1 0.06,0 0.1,-0.04 0.1,-0.1zm0.49 -0.95c-0.15,-0.16 -0.36,-0.24 -0.56,-0.24 -0.2,0 -0.41,0.08 -0.56,0.24 -0.16,0.15 -0.24,0.36 -0.24,0.56 0,0.2 0.08,0.41 0.24,0.56 0.15,0.16 0.36,0.24 0.56,0.24 0.2,0 0.41,-0.08 0.56,-0.24 0.16,-0.15 0.24,-0.36 0.24,-0.56 0,-0.2 -0.08,-0.41 -0.24,-0.56zm-0.56 -0.15c0.18,0 0.37,0.06 0.5,0.2 0.14,0.14 0.21,0.33 0.21,0.51 0,0.18 -0.07,0.37 -0.21,0.51 -0.13,0.14 -0.32,0.21 -0.5,0.21 -0.18,0 -0.37,-0.07 -0.51,-0.21 -0.14,-0.14 -0.21,-0.33 -0.21,-0.51 0,-0.18 0.07,-0.37 0.21,-0.51 0.14,-0.14 0.33,-0.2 0.51,-0.2z"
        />
      </g>
    </svg>
  )
}
