import { DeviceTypeId } from './devices'

export enum EventTypeId {
  DEVICE = 0, // 0x00 --> Dispositivo
  PASSAGE = 1, // 0x01 --> Passagem
  ON = 2, // 0x02 --> Dispositivo ligado
  MAKEUP = 3, // 0x03 --> Desperta porteiro
  SETTING = 4, // 0x04 --> Módulo programado
  KEYPRESS = 5, // 0x05 --> Tecla do módulo acionada
  PC = 6, // 0x06 --> Programação via PC
  NOTUPDATED = 7, // 0x07 --> Receptores não atualizados
  CLONING = 8, // 0x08 --> Clonagem
  PANIC = 9, // 0x09 --> Pânico
  SDCARD = 10, // 0x0A --> SDCard Removido
  RESTORE = 11, // 0x0B --> Restore realizado
  RECEIVED = 12, // 0x0C --> Evento recebido
  AUTOBACKUP = 13, // 0x0D --> Backup automatico
  BACKUP = 14, // 0x0E --> Backup manual
  INTERCOM = 15 // 0x0F --> Porteiro eletronico
}

export const eventTypes = [
  { id: EventTypeId.DEVICE, label: 'Dispositivo' },
  { id: EventTypeId.PASSAGE, label: 'Passagem' },
  { id: EventTypeId.ON, label: 'Equipmento ligado' },
  { id: EventTypeId.MAKEUP, label: 'Desperta porteiro' },
  { id: EventTypeId.SETTING, label: 'Guarita programado' },
  { id: EventTypeId.KEYPRESS, label: 'Portaria' },
  { id: EventTypeId.PC, label: 'PC' },
  { id: EventTypeId.NOTUPDATED, label: 'not updated receivers' },
  { id: EventTypeId.CLONING, label: 'Clonagem' },
  { id: EventTypeId.PANIC, label: 'Panico' },
  { id: EventTypeId.SDCARD, label: 'SDcard removido' },
  { id: EventTypeId.RESTORE, label: 'Restore realizado' },
  { id: EventTypeId.RECEIVED, label: 'Evento recebido' },
  { id: EventTypeId.AUTOBACKUP, label: 'Auto backup realizado' },
  { id: EventTypeId.BACKUP, label: 'Manual backup realizado' },
  { id: EventTypeId.INTERCOM, label: 'Porteiro electronico' }
] as const

export interface IEvent {
  id: string
  guaritaId: number
  updateCount: number
  eventType: EventTypeId
  deviceType: DeviceTypeId
  can: number
  out: number
  bloco: number
  unidade: number
  digitalId: number
  intserial: number
  date: Date
}

export interface IResponsePaginateEvents {
  data: IEvent[]
}
