import React from 'react'

import styled from 'styled-components'

export const DivTest = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.secondary};
  border: 1px solid #000;
  border-color: ${({ theme }) => theme.colors.primary};
`

const TestShared: React.FC = ({ children }) => {
  return <DivTest>{children}</DivTest>
}

export { TestShared }
