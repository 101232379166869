import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from '~/store'
import { AuthState, loadFailure } from '~/store/reducers/auth'

export function useAppAuth() {
  const appAuth = useSelector<ApplicationState, AuthState>(state => state.auth)
  const dispatch = useDispatch()

  const logout = useCallback(() => {
    dispatch(loadFailure())
  }, [dispatch])

  return { appAuth, logout }
}
