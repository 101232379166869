/* eslint-disable no-redeclare */
import { isObject } from '~/helpers/object'
import { isDefined } from '~/helpers/variables'

import type { ItemModuleFilterProps } from './ItemFilter'
import type { FilterID, IModuleFilter } from './types'

export type IModuleFilterItem = Record<FilterID, ItemModuleFilterProps['value']>

export function itemFilterValueDto(value: IModuleFilterItem): IModuleFilterItem
export function itemFilterValueDto(value?: boolean): ItemModuleFilterProps['value']
export function itemFilterValueDto(value?: any): any {
  if (isObject(value)) {
    const newFilter = Object.keys(value).reduce((acc, key) => {
      if (isDefined(value[key])) acc[key] = value[key]
      return acc
    }, {})
    return newFilter
  }
  if (isDefined(value)) return value ? 'yes' : 'no'
  return 'null'
}

export function emitFilterValueDto(value?: IModuleFilterItem): Partial<IModuleFilter> {
  const result = Object.keys(value).reduce((acc, key) => {
    if (isDefined(value[key])) {
      if (value[key] !== 'null') {
        acc[key] = !!(value[key] === 'yes')
      }
    }
    return acc
  }, {})
  return result
}
