import React from 'react'

import { CustomIconSvgProps, svgProps } from './types'

type IconDeviceSvgProps = CustomIconSvgProps & {}

export const IconDeviceSvg: React.FC<IconDeviceSvgProps> = ({ size = 72, color = 'currentColor' }) => {
  return (
    <svg {...svgProps} viewBox="0 0 99.08 99.08" width={`${size}px`} height={`${size}px`}>
      <g id="group">
        <rect id="squad" fill="none" width="99.08" height="99.08" />
        <path
          id="device"
          fill={color}
          d="M37.61 84.25l36.08 -36.09c1.72,-1.72 1.72,-4.53 0,-6.25l-16.52 -16.52c-1.72,-1.72 -4.53,-1.72 -6.25,0l-36.09 36.08c-1.72,1.72 -1.72,4.54 0,6.26l16.52 16.52c1.72,1.72 4.54,1.72 6.26,0zm2.38 -22.58c1.62,-1.62 4.24,-1.62 5.86,0 1.62,1.62 1.62,4.24 0,5.86 -1.62,1.62 -4.24,1.62 -5.86,0 -1.62,-1.62 -1.62,-4.24 0,-5.86zm-8.44 8.44c1.62,-1.62 4.25,-1.62 5.87,0 1.61,1.61 1.61,4.24 0,5.86 -1.62,1.61 -4.25,1.61 -5.87,0 -1.61,-1.62 -1.61,-4.25 0,-5.86zm-8.44 -8.45c1.62,-1.61 4.25,-1.61 5.86,0 1.62,1.62 1.62,4.25 0,5.87 -1.61,1.61 -4.24,1.61 -5.86,0 -1.61,-1.62 -1.61,-4.25 0,-5.87zm8.44 -8.43c1.62,-1.62 4.24,-1.62 5.86,0 1.62,1.62 1.62,4.24 0,5.86 -1.62,1.62 -4.24,1.62 -5.86,0 -1.62,-1.62 -1.62,-4.24 0,-5.86zm13.27 -14.8c4.37,-4.37 11.46,-4.37 15.83,0 4.37,4.37 4.37,11.46 0,15.83 -4.37,4.37 -11.46,4.37 -15.83,0 -4.37,-4.37 -4.37,-11.46 0,-15.83zm4.09 4.08c2.11,-2.12 5.54,-2.12 7.66,0 2.12,2.12 2.12,5.55 0,7.66 -2.12,2.12 -5.55,2.12 -7.66,0 -2.12,-2.11 -2.12,-5.54 0,-7.66zm17.5 -25.76c-0.88,-0.15 -1.48,-0.98 -1.33,-1.86 0.14,-0.88 0.97,-1.47 1.85,-1.33 2.32,0.38 4.57,1.11 6.68,2.19 2.04,1.04 3.95,2.4 5.63,4.09 1.69,1.69 3.05,3.59 4.09,5.63 1.08,2.11 1.81,4.36 2.19,6.68 0.14,0.88 -0.45,1.71 -1.33,1.85 -0.88,0.15 -1.71,-0.45 -1.86,-1.33 -0.33,-1.99 -0.95,-3.93 -1.87,-5.73 -0.89,-1.74 -2.06,-3.36 -3.51,-4.81 -1.45,-1.45 -3.07,-2.62 -4.81,-3.51 -1.8,-0.92 -3.74,-1.54 -5.73,-1.87zm-1.98 8.44c-0.88,-0.15 -1.48,-0.98 -1.33,-1.86 0.14,-0.88 0.98,-1.47 1.86,-1.33 1.5,0.25 2.97,0.72 4.35,1.43 1.33,0.68 2.57,1.57 3.67,2.67 1.1,1.1 1.99,2.34 2.67,3.67 0.71,1.38 1.18,2.85 1.43,4.35 0.15,0.88 -0.45,1.72 -1.33,1.86 -0.88,0.15 -1.71,-0.45 -1.86,-1.33 -0.2,-1.19 -0.57,-2.34 -1.11,-3.41 -0.53,-1.03 -1.22,-1.99 -2.08,-2.86 -0.87,-0.86 -1.83,-1.55 -2.86,-2.08 -1.07,-0.54 -2.22,-0.91 -3.41,-1.11z"
        />
      </g>
    </svg>
  )
}
