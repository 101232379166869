import React from 'react'

import { useAppTheme } from '../shared/core/src'
import { FlexOne } from '../shared/web/src/styled'

export const DarkLayout: React.FC = ({ children }) => {
  const { matchingBackgroudText } = useAppTheme()

  const colorText = matchingBackgroudText('primary')

  return (
    <FlexOne grow={1} width="100%" justify="center" align="center" backgroundTheme="primary" colorText={colorText}>
      {children}
    </FlexOne>
  )
}
