import { AppLayout } from '~/components/layout/AppLayout'

import { Alerts } from '~/pages/admin/NewAlerts'
import { Events } from '~/pages/Events'
import { Module } from '~/pages/Module'
import { Modules } from '~/pages/Modules'
import { PageSearchSerial } from '~/pages/PageSearchSerial'

import type { IAppRoute } from './types'
export type { IAppRoute }

export const privateRouteList: Partial<IAppRoute>[] = [
  { path: '/modules', Page: Modules, title: 'Módulos' },
  { path: '/modules/:id', Page: Module },
  { path: '/events/:id', Page: Events },
  { path: '/search-serial', Page: PageSearchSerial, title: 'Buscar serial' },
  { path: '/alerts', Page: Alerts, title: 'Alertas agendados' }
  //
]

export const privateRoutes: IAppRoute[] = privateRouteList.map(route => {
  return { ...route, exact: true, Layout: route.Layout || AppLayout } as IAppRoute
})
