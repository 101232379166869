import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CircleLoading } from '~/components/CircleLoading'
import { FoundDeviceItem } from '~/components/devices/FoundDeviceItem'
import { Container } from '~/components/layout/Container'
import { PageTitle } from '~/components/PageTitle'
import { SearchBar } from '~/components/SearchBar'
import { useAppTheme } from '~/components/shared/web/src'
import { Paragraph } from '~/components/shared/web/src/styled'
import { ToolBar } from '~/components/ToolBar'
import { ToolSvgButton } from '~/components/ToolBar/ToolSvgButton'

import { useIsMounted } from '~/hooks/useIsMounted'
import Api from '~/services/Api'
import { IResponseSearchDevices, IDevice } from '~/services/Api/devices'

export const PageSearchSerial: React.FC = () => {
  const { theme } = useAppTheme()
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [data, setData] = useState<IDevice[]>([])
  const natigate = useHistory()

  const clearAll = useCallback((clearData?: boolean) => {
    setHasError(false)
    if (clearData) setData([])
  }, [])

  const searchData = useCallback(
    async (textSerial?: string) => {
      if (textSerial) {
        setLoading(true)
        const url = `/devices/search-serial?serial=${textSerial.toLocaleUpperCase()}`
        const response = await Api.getDefault<IResponseSearchDevices>(url)
        if (isMounted.current) {
          setLoading(false)
          if (response?.success) {
            setData(response.devices)
          } else {
            setHasError(true)
          }
        }
      } else {
        clearAll(true)
      }
    },
    [isMounted, clearAll]
  )

  const handleSearchChange = useCallback(
    (text?: string) => {
      searchData(text)
    },
    [searchData]
  )

  return (
    <div>
      <Container horizontalSpaced>
        <PageTitle spotlight={'Pesquisa de'} title="Serial">
          <ToolBar justify="flex-end">
            <ToolSvgButton iconName="module" onClick={() => natigate.push('/modules')} />
          </ToolBar>
        </PageTitle>
        <Container verticalSpaced>
          <SearchBar
            onChangeText={handleSearchChange}
            placeholder="digite o serial"
            hasError={hasError}
            messageError={'* Numero serial inválido'}
          />
        </Container>
      </Container>
      <Container horizontalSpaced>
        {!data.length ? (
          <Paragraph color={theme.colors.textDark} size={10} align="center">
            Nenhum resultado obtido. Pesquise por um serial para ver resultados
          </Paragraph>
        ) : (
          <>
            <PageTitle title={`Resultados obtidos: (${data.length})`} />
            {data.map((device, i) => {
              const count = i + 1
              const animeTime = 600 + count * 200
              return <FoundDeviceItem key={device.id} animeTime={animeTime} {...device} />
            })}
          </>
        )}

        {loading ? <CircleLoading /> : null}
      </Container>
    </div>
  )
}
