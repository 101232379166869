import React, { createContext, useState, useCallback, useContext } from 'react'

import { removeInvalidValues } from '~/helpers/object'

interface IFilterContext<T> {
  filter: Partial<T>
  updateFilter: (data: Partial<T>) => void
  resetFilter: (persistData?: Partial<T>, callback?: (data: Partial<T>) => void) => void
}

export const FilterContext = createContext<IFilterContext<unknown>>({} as IFilterContext<unknown>)

interface Props {
  initialFilter?: Record<string, any>
  fetchData?: <T>(filter?: IFilterContext<T>['filter']) => void
}

export const FilterProvider: React.FC<Props> = ({ children, initialFilter = {}, fetchData }) => {
  const [filter, setFilter] = useState(initialFilter)

  const updateFilter = useCallback(
    (data = {}) => {
      const newFilter = removeInvalidValues({ ...filter, ...data })
      setFilter(newFilter)
      // const t = removeInvalidValues(newFilter)
      // console.log('newFilter', newFilter, t)

      if (fetchData) fetchData(newFilter)
    },
    [fetchData, filter]
  )

  const resetFilter = useCallback(
    (persistData = {}, callback) => {
      setFilter(persistData)
      if (callback) callback(filter)
      if (fetchData) fetchData(removeInvalidValues(persistData))
    },
    [filter, fetchData]
  )

  return <FilterContext.Provider value={{ filter, updateFilter, resetFilter }}>{children}</FilterContext.Provider>
}

export function useFilter<T>() {
  return useContext(FilterContext) as IFilterContext<T>
}
