import { ResponseApi } from '~/services/BaseApi/types'

/* eslint-disable no-unused-vars */
export enum LevelTypes {
  SINDICO = 3,
  GESTOR = 7,
  ADMIN = 8,
  DEV = 9
}
export interface IAuthUser {
  id: number | string
  companyId?: number
  email: string
  level: number | LevelTypes
}
export interface IUser {
  id: number
  name: string
  level: number
  email: string
  meta?: string
  nasc?: Date
  phone?: string
  rg?: string
  actived?: boolean
  bloq?: boolean
  mobile?: string
  companyId?: number
  cpf?: string
  emok?: boolean
  firstName: string
  genre?: 'M' | 'F'
  // readonly createdAt: Date;
  // readonly createdBy: number;
  // updatedAt: Date;
  // updatedBy: number;
}

export interface IUserContact {
  id: number
  name: string
  phone?: string
  mobile?: string
  email?: string
}

export type UserOption = Pick<IUser, 'id' | 'name' | 'mobile'>
export interface IResponseUserOptions extends ResponseApi {
  users: UserOption[]
}
