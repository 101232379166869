import React from 'react'

import { FormLogin } from '~login/FormLogin'

import { Container } from '~/components/layout/Container'
import { DarkLayout } from '~/components/layout/DarkLayout'
import { Logo } from '~/components/Logo'
import { FlexOne } from '~/components/shared/web/src/styled'

export const PageLogin: React.FC = () => {
  return (
    <DarkLayout>
      <FlexOne grow={3} justify="center" align="center">
        <Container horizontalSpaced>
          <Logo />
          <FormLogin />
        </Container>
      </FlexOne>
      <FlexOne grow={1} />
    </DarkLayout>
  )
}
