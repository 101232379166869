import React, { useEffect, useRef, useState } from 'react'

import { useField } from '@unform/core'
import styled from 'styled-components'

import { LabelIcon } from './LabelIcon'
import { Field, Input } from './styles'

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.l}px;
  ${Field} {
    padding-left: ${({ theme }) => theme.spacing.l}px;
  }
`

export type InputIconProps = React.InputHTMLAttributes<HTMLInputElement> & {
  size?: number
  name: string
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

export const InputIcon: React.FC<InputIconProps> = ({ size = 38, name, onFocus, onBlur, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [focused, setFocused] = useState(false)
  const id = `inputicon-${name}`

  const { fieldName, defaultValue, registerField, error } = useField(name)

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true)
    if (onFocus) onFocus(e)
  }
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false)
    if (onBlur) onBlur(e)
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: ref => {
        ref.current.value = ''
      }
    })
  }, [fieldName, registerField])

  return (
    <Container>
      <Field size={size} grow={1}>
        <Input
          ref={inputRef}
          id={id}
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          defaultValue={defaultValue}
          error={error}
          {...rest}
        />
      </Field>
      <LabelIcon size={size} focused={!!focused} htmlFor={id} />
    </Container>
  )
}
