import React, { useCallback, useState } from 'react'

import { TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'

import { useIsMounted } from '~/hooks/useIsMounted'
import type { IAlertMetadata } from '~/services/Api/alerts'
import type { IUpdateItemItemsParams, IAlertItems } from '~/services/Api/alerts-items'

type RealtimeType = IAlertMetadata['realtime']
type Props = {
  alert?: IAlertItems['alert']
  onSave?: (data: IUpdateItemItemsParams) => Promise<boolean>
}

export const FormRealTime: React.FC<Props> = ({ alert, onSave }) => {
  const isMounted = useIsMounted()
  const [data, setData] = useState<IAlertMetadata['realtime']>(alert?.meta?.realtime || {})
  const [disabled, setDisabled] = useState(!alert?.realtime)
  const [saving, setSaving] = useState(false)

  const handleChangeCheck = useCallback((prop: keyof RealtimeType) => {
    return (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setData(old => ({ ...old, [prop]: !!checked }))
    }
  }, [])

  const handleDisable = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setDisabled(!checked)
  }, [])

  const handleChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pathHttp = event?.target?.value || ''
    setData(old => ({ ...old, pathHttp }))
  }

  const handleSave = useCallback(async () => {
    if (onSave) {
      setSaving(true)
      const meta = (alert?.meta || {}) as IAlertMetadata
      await onSave({
        alert: {
          realtime: !disabled,
          meta: { ...meta, realtime: { ...data } }
        }
      })
      if (isMounted.current) {
        setSaving(false)
      }
    }
  }, [isMounted, onSave, data, alert, disabled])

  return (
    <Grid spacing={1} container style={{ padding: 10 }}>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={<Switch onChange={handleDisable} checked={!disabled} />}
            label="Utilizar alerta em tempo real"
          />
        </FormGroup>
        <Divider />
      </Grid>
      <Grid item>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={!!data?.email} onChange={handleChangeCheck('email')} disabled={disabled} />}
            label="Envio por e-mail"
          />
          <FormControlLabel
            control={<Switch checked={!!data?.telegram} onChange={handleChangeCheck('telegram')} disabled={disabled} />}
            label="Envio por Telegram"
          />
        </FormGroup>
      </Grid>
      <Grid item>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={!!data?.whatsapp} onChange={handleChangeCheck('whatsapp')} disabled={disabled} />}
            label="Envio por Whatsapp"
          />
          <FormControlLabel
            control={<Switch checked={!!data?.hookHttp} onChange={handleChangeCheck('hookHttp')} disabled={disabled} />}
            label="POST HTTP"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={8}>
        {data?.hookHttp ? (
          <TextField
            required
            id="data.pathHttp"
            variant="standard"
            label="Post URL"
            fullWidth
            placeholder="https://exemplo.com.br/rota"
            value={data?.pathHttp || ''}
            onChange={handleChangeUrl}
            disabled={disabled}
          />
        ) : null}
      </Grid>
      <Grid item alignContent={'center'} alignItems={'center'} alignSelf="flex-end" style={{ paddingBottom: 8 }}>
        <Button title="SALVAR" size="small" variant="contained" onClick={handleSave} disabled={!!saving}>
          SALVAR
        </Button>
      </Grid>
    </Grid>
  )
}
