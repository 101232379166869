import { useCallback, useState, useEffect } from 'react'

export function useWindowDimensions() {
  const getWindowDimensions = useCallback(() => {
    return { width: window?.innerWidth ?? 0, height: window?.innerHeight ?? 0 }
  }, [])
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window?.addEventListener('resize', handleResize)
    return () => window?.removeEventListener('resize', handleResize)
  }, [getWindowDimensions])

  return windowDimensions
}
