import React from 'react'

import { ThemeProvider } from 'styled-components'

import { useAppTheme } from '../../../core/src'

export const StyledProvider: React.FC = ({ children }) => {
  const { theme } = useAppTheme()
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
