import styled from 'styled-components'

export const EventContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.rounded}px;
  margin-bottom: ${({ theme }) => theme.spacing.l}px;
  box-shadow: ${({ theme }) => theme.colors.shadow} 3px 3px 0;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing.s}px;
`

export const EventContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
`
