import { NullLayout } from '~/components/layout/NullLayout'

import { PageForgot } from '~/pages/Forgot'
import { PageLogin } from '~/pages/Login'
import { PageNoFound } from '~/pages/NoFound'

import type { IAppRoute } from './types'

const publicRouteList: Partial<IAppRoute>[] = [
  { path: '/', Page: PageLogin },
  { path: '/forgot', Page: PageForgot },
  { path: '*', Page: PageNoFound }
  //
]

export const publicRoutes: IAppRoute[] = publicRouteList.map(route => {
  return { ...route, exact: true, Layout: route.Layout || NullLayout } as IAppRoute
})
