import React from 'react'

import styled from 'styled-components'

import logo from '~/assets/images/logo-des.svg'

import { HeaderTools } from './HeaderTools'

const Logo = styled.div`
  height: 50px;
  img {
    max-height: 100%;
    display: block;
    margin: 0 auto;
    outline: none;
    border: 0;
  }
`
const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: ${({ theme }) => theme.spacing.l}px;
`

export const Header: React.FC = () => {
  return (
    <HeaderContent>
      <Logo>
        <img src={logo} alt="logo" />
      </Logo>
      <HeaderTools />
    </HeaderContent>
  )
}
