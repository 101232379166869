import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'

import { appName } from '~/config'
import { querystring } from '~/helpers/string'
import type { AppStore } from '~/store'
import { AuthState, loadFailure, setTokens } from '~/store/reducers/auth'

import type { ApiConfig, IApiStorageData, IResponseRefreshToken, ResponseApi } from './types'

type SetTokens = Pick<AuthState, 'token' | 'refreshToken'>

export class BaseApi {
  public fetchId: number
  public readonly Api: AxiosInstance
  public readonly appName: string
  private readonly enableRefreshToken: boolean
  private readonly store: AppStore

  constructor(store: AppStore, { enableRefreshToken, ...options }: ApiConfig) {
    this.Api = Axios.create(options)
    this.store = store
    this.appName = appName
    this.enableRefreshToken = !!enableRefreshToken
    this.fetchId = 0

    this.setInterceptorRequest()
    this.setInterceptorResponse()
  }

  private setInterceptorRequest(): void {
    this.Api.interceptors.request.use(config => {
      const token = this.getApiStorageData()?.token
      if (token) config.headers.Authorization = `Bearer ${token}`
      return config
    })
  }

  private setInterceptorResponse() {
    const interceptor = this.Api.interceptors.response.use(
      res => res,
      (error?: AxiosError) => {
        const response = error && error?.response
        const errorMessage = error ? `${error.code || error.message}` : 'timeout'

        const data: any = { success: false, message: errorMessage }
        if (!response) return Promise.resolve({ data })

        const excludeMessages = ['invalid_refreshToken', 'invalid_credentials']
        if (this.enableRefreshToken && response?.status === 401 && !excludeMessages.includes(response?.data?.message)) {
          this.Api.interceptors.response.eject(interceptor)
          return this.refreshTokenCallback(response.config)
        }

        return Promise.resolve(response)
      }
    )
  }

  private errorOf(message: string): ResponseApi {
    return {
      success: false,
      message
    }
  }

  private normalizeUrl(path?: string): string {
    const [base = '', query = ''] = `${path}`.split('?')
    const params: any = querystring(query)
    params.fetchId = this.fetchId

    return [base.replace(/^(.*)\/$/, '$1'), querystring(params)].join('?')
  }

  private updateStorageTokens({ token = '', refreshToken = '' }: SetTokens): void {
    this.store?.dispatch(setTokens({ token, refreshToken }))
  }

  private clearTokens(messageError?: string) {
    this.store?.dispatch(loadFailure(messageError))
  }

  private getApiStorageData(): IApiStorageData {
    const { auth } = this.store?.getState()
    return {
      token: auth?.token || '',
      refreshToken: auth?.refreshToken || ''
    }
  }

  private async refreshTokenCallback(config: AxiosRequestConfig) {
    const res = await this.refreshToken()

    const clear = () => {
      this.clearTokens(res?.message)
      this.setInterceptorResponse()
    }

    if (res.success) {
      this.updateStorageTokens({ refreshToken: res.refreshToken, token: res.token })
      config.headers.Authorization = `Bearer ${res.token}`
      try {
        return this.Api(config).finally(() => this.setInterceptorResponse())
      } catch (error) {
        clear()
        return this.errorOf('erro ao repetir consulta')
      }
    }
    clear()
    return res
  }

  async refreshToken(): Promise<IResponseRefreshToken> {
    const refreshToken = this.getApiStorageData()?.refreshToken
    if (refreshToken) {
      try {
        const response = await this.Api.post<IResponseRefreshToken>('/auth/refresh', {
          refreshToken,
          appName: this.appName
        })
        return response && response.data
      } catch (error) {
        return this.errorOf(error?.message)
      }
    }
    return this.errorOf('tokenless_storage')
  }

  async getDefault<T = ResponseApi>(path?: string, config?: AxiosRequestConfig): Promise<T> {
    this.fetchId += 1
    const url = this.normalizeUrl(path)
    const response = await this.Api.get(url, config)
    return response && response.data
  }

  async postDefault<T = ResponseApi, P = any>(path: string, payload: P, config?: AxiosRequestConfig): Promise<T> {
    this.fetchId += 1
    const url = this.normalizeUrl(path)
    const response = await this.Api.post(url, payload, config)
    return response && response.data
  }

  async patchDefault<T = ResponseApi, P = any>(path: string, payload: P, config?: AxiosRequestConfig): Promise<T> {
    this.fetchId += 1
    const url = this.normalizeUrl(path)
    const response = await this.Api.patch(url, payload, config)
    return response && response.data
  }
}
