import React, { useMemo } from 'react'

import { CustomIconSvgProps, svgProps } from './types'

export type ModuleOnlineSvgProps = CustomIconSvgProps & {
  online?: boolean
  workoff?: boolean
}

const paths = [
  // workoff
  'M76.24 42.42c-0.96,-4.88 -3.22,-9.29 -6.4,-12.86l-28.31 49.04 33.44 0c10.03,0 18.17,-8.13 18.17,-18.16 0,-9.59 -7.45,-17.36 -16.9,-18.02zm-19.28 -20.92c-2.36,-0.67 -4.84,-1.02 -7.42,-1.02 -10.5,0 -19.62,5.96 -24.16,14.67 -10.93,1.17 -19.44,10.43 -19.44,21.66 0,10.8 7.9,19.79 18.22,21.5l32.8 -56.81zm-31.91 63.2l42.69 -73.95 6.29 3.63 -42.69 73.95 -6.29 -3.63z',
  // online
  'M76.24 42.42c-2.47,-12.53 -13.47,-21.94 -26.7,-21.94 -10.5,0 -19.62,5.96 -24.16,14.67 -10.93,1.17 -19.44,10.43 -19.44,21.66 0,12.02 9.78,21.79 21.8,21.79l47.23 0c10.03,0 18.17,-8.13 18.17,-18.16 0,-9.59 -7.45,-17.36 -16.9,-18.02zm-33.96 25.28l-12.72 -12.71 5.12 -5.12 7.6 7.56 18.81 -18.79 5.12 5.12 -23.93 23.94z',
  // offline
  'M76.24 42.42c-2.47,-12.53 -13.47,-21.94 -26.7,-21.94 -10.5,0 -19.62,5.96 -24.16,14.67 -10.93,1.17 -19.44,10.43 -19.44,21.66 0,12.02 9.78,21.79 21.8,21.79l47.23 0c10.03,0 18.17,-8.13 18.17,-18.16 0,-9.59 -7.45,-17.36 -16.9,-18.02zm-18.13 -3.79l-9.4 9.41 -9.41 -9.41 -5.13 5.13 9.4 9.41 -9.4 9.4 5.13 5.13 9.41 -9.4 9.4 9.4 5.13 -5.13 -9.4 -9.4 9.4 -9.41 -5.13 -5.13z'
]

export const ModuleOnlineSvg: React.FC<ModuleOnlineSvgProps> = ({ size = 72, color, workoff, online }) => {
  const [pathColor, path] = useMemo(() => {
    if (workoff) return [color || '#999999', paths[0]]
    return online ? [color || '#6AA486', paths[1]] : [color || '#B8584D', paths[2]]
  }, [online, workoff, color])

  return (
    <svg {...svgProps} width={`${size}px`} height={`${size}px`} viewBox="0 0 99.08 99.08">
      <g id="group">
        <rect fill="none" width="99.08" height="99.08" />
        <path fill={pathColor} d={path} />
      </g>
    </svg>
  )
}
