import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import { privateRoutes } from './private'
import { PrivateRoute } from './PrivateRoute'
import { publicRoutes } from './public'
import { PublicRoute } from './PublicRoute'

export const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        {privateRoutes.map(route => {
          return <PrivateRoute key={`route-${route.path}`} {...route} />
        })}
        {publicRoutes.map(route => {
          return <PublicRoute key={`route-${route.path}`} {...route} />
        })}
      </Switch>
    </BrowserRouter>
  )
}
