import React, { useCallback, useMemo, useState } from 'react'

import { Divider, FormControlLabel, FormGroup, Switch } from '@mui/material'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { format, isValid, parse } from 'date-fns'

import { useIsMounted } from '~/hooks/useIsMounted'
import type { IAlertMetadata } from '~/services/Api/alerts'
import type { IAlertItems, IUpdateItemItemsParams } from '~/services/Api/alerts-items'

function toDate(time: string) {
  const date = parse(time, 'HH:mm:ss', new Date())
  if (isValid(date)) return date
  return null
}
type SummaryType = IAlertMetadata['summary']

type Props = {
  alert?: IAlertItems['alert']
  onSave: (data: IUpdateItemItemsParams) => Promise<boolean>
}
export const FormSummary: React.FC<Props> = ({ alert, onSave }) => {
  const alertMeta = useMemo(() => (alert?.meta || {}) as IAlertItems['alert']['meta'], [alert])

  const [data, setData] = useState<SummaryType>(alert?.meta?.summary || {})
  const isMounted = useIsMounted()
  const [saving, setSaving] = useState(false)
  const [mode, setMode] = useState(data?.mode || '')
  const [hours, setHours] = useState(data?.lastHours || 0)
  const [time, setTime] = useState(toDate(data?.hour))
  const [disabled, setDisabled] = useState(!alert?.summary)

  const handleChangeCheck = useCallback((prop: keyof SummaryType) => {
    return (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setData(old => ({ ...old, [prop]: !!checked }))
    }
  }, [])

  const handleDisable = useCallback((_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setDisabled(!checked)
  }, [])

  const handleChangeMode = (event: SelectChangeEvent) => {
    setMode(event.target?.value || '')
  }

  const handleChangeHours = (event: SelectChangeEvent) => {
    setHours(+event.target?.value || 0)
  }

  const handleChangeTime = (date: Date) => {
    setTime(date || null)
  }

  const handleSave = useCallback(async () => {
    if (onSave) {
      setSaving(true)
      await onSave({
        alert: {
          meta: {
            ...alertMeta,
            summary: {
              ...data,
              hour: (time && format(time, 'HH:mm:ss')) || null,
              lastHours: hours,
              mode: mode || null
            }
          }
        }
      })
      if (isMounted.current) {
        setSaving(false)
      }
    }
  }, [isMounted, onSave, alertMeta, time, hours, mode, data])

  return (
    <>
      <Grid spacing={1} container style={{ padding: 10 }}>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={<Switch onChange={handleDisable} checked={!disabled} />}
              label="Utilizar alerta agendado"
            />
          </FormGroup>
          <Divider />
        </Grid>

        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={!!data?.email} onChange={handleChangeCheck('email')} disabled={true} />}
              label="Envio por e-mail"
            />
            <FormControlLabel
              control={<Switch checked={!!data?.telegram} onChange={handleChangeCheck('telegram')} disabled={true} />}
              label="Envio por Telegram"
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={!!data?.whatsapp} onChange={handleChangeCheck('whatsapp')} disabled={disabled} />
              }
              label="Envio por Whatsapp"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid spacing={1} container style={{ padding: 10 }}>
        <Grid item>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 130 }}>
            <InputLabel id="label-mode">Recorrência</InputLabel>
            <Select
              labelId="label-mode"
              id="select-mode"
              value={mode}
              onChange={handleChangeMode}
              label="Recorrência"
              defaultValue={mode}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'daily'}>Diariamente</MenuItem>
              <MenuItem value={'once'}>Uma vez</MenuItem>
              <MenuItem value={'monthly'}>Mensalmente</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 130 }}>
            <InputLabel id="label-lastHours">Ocorrências</InputLabel>
            <Select
              labelId="label-lastHours"
              id="select-lastHours"
              value={`${hours}`}
              onChange={handleChangeHours}
              label="Occorências"
              defaultValue={`${hours}`}
            >
              <MenuItem value="0">
                <em>None</em>
              </MenuItem>
              <MenuItem value={12}>Últimas 12 horas</MenuItem>
              <MenuItem value={24}>Últimas 24 horas</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="standard" sx={{ m: 1, width: 100 }}>
            <TimePicker
              label="Horário"
              value={time}
              onChange={handleChangeTime}
              renderInput={params => <TextField label="Horário" variant="standard" {...params} />}
            />
          </FormControl>
        </Grid>
        <Grid item alignContent={'center'} alignItems={'center'} alignSelf="flex-end" style={{ paddingBottom: 8 }}>
          <Button title="SALVAR" size="small" variant="contained" onClick={handleSave} disabled={!!saving}>
            SALVAR
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
