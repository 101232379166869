import React, { useCallback, useEffect, useState } from 'react'

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import { getAlertItem, IAlertItems, IUpdateItemItemsParams } from '~/services/Api/alerts-items'

import { useIsMounted } from '../../../hooks/useIsMounted'
import { CircleLoading } from '../../CircleLoading'
import { FormDestination } from './FormDestination'
import { FormRealTime } from './FormRealtime'
import { FormSummary } from './FormSummary'
import { ExpandedContainer, TabPanelContainer } from './styles'

type TabPanelProps = {
  index: number
  value: number
  alertItemId: number
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, alertItemId, ...other }) => {
  return (
    <TabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`${alertItemId}-tabpanel-${index}`}
      aria-labelledby={`${alertItemId}-tabpanel-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </TabPanelContainer>
  )
}

function a11yProps(index: number, alertItemId: number) {
  return { id: `${alertItemId}-tab-config-${index}`, 'aria-controls': `${alertItemId}-tabpanel-${index}` }
}

type Props = {
  alertItemId: number
  onSave: (data: IUpdateItemItemsParams) => Promise<boolean>
}
export const AlertItemTabs: React.FC<Props> = ({ alertItemId, onSave }) => {
  const isMounted = useIsMounted()
  const [tabIndex, setTabIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<IAlertItems>(null)

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => setTabIndex(newValue)

  const fetchData = useCallback(async () => {
    setLoading(true)
    const response = await getAlertItem(alertItemId)
    if (isMounted.current) {
      setLoading(false)
      if (response?.success) {
        setData(response?.alertItem)
      }
    }
  }, [alertItemId, isMounted])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <ExpandedContainer>
      {loading ? (
        <CircleLoading backgroundColor="#fff" />
      ) : (
        <>
          <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="configurações">
            <Tab label="Agenda" {...a11yProps(0, alertItemId)} />
            <Tab label="Tempo real" {...a11yProps(1, alertItemId)} />
            <Tab label="Destinatários" {...a11yProps(2, alertItemId)} />
          </Tabs>
          <TabPanel value={tabIndex} index={0} alertItemId={alertItemId}>
            {data ? <FormSummary alert={data?.alert} onSave={onSave} /> : null}
          </TabPanel>
          <TabPanel value={tabIndex} index={1} alertItemId={alertItemId}>
            {data ? <FormRealTime alert={data?.alert} onSave={onSave} /> : null}
          </TabPanel>
          <TabPanel value={tabIndex} index={2} alertItemId={alertItemId}>
            {data ? <FormDestination alertMeta={data?.alert?.meta} alertItemId={alertItemId} onSave={onSave} /> : null}
          </TabPanel>
        </>
      )}
    </ExpandedContainer>
  )
}
