import React from 'react'

import { CustomIconSvgProps, svgProps } from './types'

type IconTimerSvgProps = CustomIconSvgProps & {}

export const IconTimerSvg: React.FC<IconTimerSvgProps> = ({ size = 72, color = 'currentColor' }) => {
  return (
    <svg {...svgProps} viewBox="0 0 99.08 99.08" width={`${size}px`} height={`${size}px`}>
      <g id="_1685737028800">
        <rect id="squad" fill="none" width="99.08" height="99.08" />
        <path
          id="timer"
          fill={color}
          d="M67.08 29.54l7.38 -8 6.77 6.15 -8 8c4.31,4.93 6.77,11.7 6.77,19.08 0,17.23 -13.54,30.77 -30.77,30.77 -17.23,0 -30.77,-13.54 -30.77,-30.77 0,-14.15 9.23,-26.46 22.77,-30.15l0 -11.08 16 0 0 11.08c3.81,1.02 7.58,3.1 9.85,4.92zm-17.85 3.54c11.98,0 21.7,9.71 21.7,21.69 0,11.98 -9.72,21.7 -21.7,21.7 -11.98,0 -21.69,-9.72 -21.69,-21.7 0,-11.98 9.71,-21.69 21.69,-21.69z"
        />
        <g id="group-pointer">
          <circle id="pointer-register" fill="none" cx="49.23" cy="54.77" r="15.34" />
          <path
            id="pointer"
            fill={color}
            d="M59.69 43.69c-0.62,1.45 -5.79,13.8 -6.77,14.77 -2.71,2.71 -5.85,1.75 -7.59,-0.39 -1.62,-1.99 -2.03,-4.98 0.21,-6.99 0.4,-0.36 12.75,-6.68 14.15,-7.39z"
          />
        </g>
      </g>
    </svg>
  )
}
