import { IAuthUser, IResponseUserOptions, IUser, IUserContact, LevelTypes, UserOption } from './users.dto'

import Api from '..'

export type { IAuthUser, IUser, IUserContact, UserOption }
export { LevelTypes }

export async function listUserOptions() {
  const response = await Api.getDefault<IResponseUserOptions>('/users/options')
  return response
}
