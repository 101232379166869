import React, { useState, useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router'

import { parse } from 'query-string'

import { CircleLoading } from '~/components/CircleLoading'
import { EventFilter, IFilterEvent } from '~/components/Events/EventFilter'
import { EventItem } from '~/components/Events/EventItem'
import { FilterProvider } from '~/components/Filter/FilterProvider'
import { Container } from '~/components/layout/Container'
import { PageTitle } from '~/components/PageTitle'
import { Paragraph } from '~/components/shared/web/src/styled'

import { querystring } from '~/helpers/string'
import { useIsMounted } from '~/hooks/useIsMounted'
import Api, { IResponsePaginate } from '~/services/Api'
import { IEvent } from '~/services/Api/events'

const pageSize = 20

type PageParams = { id?: string }
interface IPagination extends Partial<IEvent> {
  page?: number
  size?: number
}

export const Events: React.FC = () => {
  const query = parse(location.search)
  const initialFilter: IFilterEvent = {
    eventType: parseInt(`${query?.eventType}`),
    deviceType: parseInt(`${query?.deviceType}`) || null
  }

  const params = useParams<PageParams>()
  const guaritaId = parseInt(params?.id, 10) || 0

  const [loading, setLoading] = useState(false)
  const isMounted = useIsMounted()

  const [data, setData] = useState<IEvent[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [pagination, setPagination] = useState<IPagination>({ page: 1, size: pageSize })

  const fetchData = useCallback(
    async (paginate: Partial<IPagination> = {}) => {
      if (guaritaId) {
        const query: IPagination = { page: paginate?.page ?? 1, size: pageSize, ...paginate }
        if (query.page === 1) setLoading(true)

        const url = `/events/${guaritaId}?${querystring(query)}`
        const response = await Api.getDefault<IResponsePaginate<IEvent>>(url)
        if (isMounted.current) {
          setLoading(false)
          if (response?.success) {
            setData(old => [...old, ...response.data])
            setPagination({ page: response.page })
            if (response.page >= response.pages) setHasMore(false)
          }
        }
      }
    },
    [isMounted, guaritaId]
  )

  const updateFilter = useCallback(
    (data: Partial<IFilterEvent>) => {
      setPagination(old => ({ ...old, page: 1 }))
      setData([])
      fetchData({ page: 1, ...data })
    },
    [fetchData]
  )

  const handleFetchMoreData = useCallback(() => {
    fetchData({ page: (pagination?.page || 1) + 1 })
  }, [fetchData, pagination])

  const renderMessage = useCallback((text: string) => {
    return (
      <Paragraph align="center" themeColor="textDark">
        {text}
      </Paragraph>
    )
  }, [])

  return (
    <div>
      <Container horizontalSpaced>
        <PageTitle spotlight="Eventos" title="Relatório" back />
        <PageTitle spotlight="Resultados" title="" />
      </Container>
      <Container horizontalSpaced>
        <FilterProvider fetchData={updateFilter} initialFilter={initialFilter}>
          <EventFilter />
        </FilterProvider>
      </Container>

      <InfiniteScroll
        dataLength={data.length}
        scrollableTarget="layout-container"
        hasMore={!!hasMore}
        next={handleFetchMoreData}
        loader={
          <Container>
            {pagination.page > 1 ? <CircleLoading size={20} relative backgroundColor="#0000" /> : null}
          </Container>
        }
        endMessage={renderMessage('FIM')}
      >
        {data.map(item => {
          return <EventItem key={`event-${item.id}-${item.bloco}`} {...item} />
        })}
      </InfiniteScroll>
      {loading ? <CircleLoading /> : null}
    </div>
  )
}
