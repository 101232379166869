import React, { FC, useMemo, memo } from 'react'

import { isDefined } from '~/helpers/variables'
import { useApp } from '~/hooks/useApp'

import Editable, { EditableProps } from './Editable'

export interface WithEditableProps extends Omit<EditableProps, 'html' | 'children'> {
  editActive?: boolean
  apiName?: string
}

export default function withEditable<T>(Component: FC<T>) {
  const EditableComponent: FC<WithEditableProps & T> = ({
    editActive,
    tagName,
    onSave,
    onEdit,
    onEditChange,
    onToggle,
    editing,
    children,
    ...rest
  }) => {
    const { editable } = useApp()

    const html = useMemo(() => {
      return typeof children === 'string' ? children : JSON.stringify(children)
    }, [children])

    const edt = useMemo(() => {
      return isDefined(editActive) ? !!editActive : !!editable
    }, [editActive, editable])

    if (!edt) {
      const props = rest as T
      return <Component {...props}>{children}</Component>
    }

    return (
      <Editable
        html={html}
        tagName={tagName}
        onSave={onSave}
        onEdit={onEdit}
        onEditChange={onEditChange}
        onToggle={onToggle}
        editing={editing}
        {...rest}
      />
    )
  }
  return memo(EditableComponent) as typeof EditableComponent
}
