import React, { useCallback, useMemo, useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import { useUserOptions } from '~/hooks/useAppUser'
import { useIsMounted } from '~/hooks/useIsMounted'
import { IAlertMetadata } from '~/services/Api/alerts'
import { IUpdateItemItemsParams } from '~/services/Api/alerts-items'
import type { UserOption } from '~/services/Api/users'

import { LiItem } from './styles'

type FormDestinationProps = {
  alertItemId: number
  alertMeta?: IAlertMetadata
  onSave?: (data: IUpdateItemItemsParams) => Promise<boolean>
  userOptions?: UserOption[]
  loading?: boolean
}
export const FormDestination: React.FC<FormDestinationProps> = ({ alertItemId, alertMeta, onSave }) => {
  const isMounted = useIsMounted()
  const [userOptions, loading] = useUserOptions()
  const [selected, setSelected] = useState<number[]>(alertMeta?.sendTo || [])

  const [saving, setSaving] = useState(false)

  const defaultSelected: UserOption[] = useMemo(() => {
    return userOptions.filter(f => alertMeta?.sendTo.find(id => id === f.id))
  }, [userOptions, alertMeta])

  const handleSave = useCallback(async () => {
    if (onSave) {
      setSaving(true)
      await onSave({ alert: { meta: { ...alertMeta, sendTo: selected } } })
      if (isMounted.current) {
        setSaving(false)
      }
    }
  }, [isMounted, onSave, alertMeta, selected])

  const handleChange = useCallback((event, value: UserOption[]) => {
    setSelected(value?.map(v => v.id))
  }, [])
  return (
    <Grid spacing={1} container>
      <Grid item xs={8}>
        <FormControl variant="standard" sx={{ m: 1 }} fullWidth style={{ maxWidth: '100%' }}>
          <Autocomplete
            key={`${userOptions?.length}-${defaultSelected.length}`}
            multiple
            id={`${alertItemId}-tags-user-options`}
            loading={!!loading}
            options={userOptions}
            value={userOptions.filter(f => selected.includes(f.id))}
            filterSelectedOptions
            onChange={handleChange}
            getOptionLabel={(option: UserOption) => option?.name}
            renderOption={(props, option) => {
              return (
                <LiItem {...props} key={option.id}>
                  {option.name}
                  {option?.mobile ? <span> {option.mobile}</span> : null}
                </LiItem>
              )
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Destinatários"
                placeholder="Escolha"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        alignContent={'center'}
        alignItems={'center'}
        alignSelf="flex-end"
        style={{ paddingBottom: 8, paddingLeft: 20 }}
      >
        <Button title="SALVAR" size="small" variant="contained" onClick={handleSave} disabled={!!saving}>
          SALVAR
        </Button>
      </Grid>
    </Grid>
  )
}
