import React from 'react'
import { FiMoreVertical } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

import { SimpleText, useAppTheme } from '@shared-web/index'

import { ModuleOnlineSvg } from '../icons/ModuleOnlineSvg'
import { Container } from '../layout/Container'
import {
  IconContainer,
  DescriptionContainer,
  ModuleTitle,
  VersionContainer,
  MoreContainer,
  DescriptionLine,
  ModuleContainer
} from './styles'

export type ModuleItemProps = {
  id: string | number
  name: string
  ip?: string
  mac: string
  version: string
  ping: number
  workoff?: boolean
  online?: boolean
}

export const ModuleItem: React.FC<ModuleItemProps> = ({ id, name, ip, mac, version, ping, workoff, online }) => {
  const { theme } = useAppTheme()
  const natigate = useHistory()

  const handleClickMore = () => {
    natigate.push(`/modules/${id}`)
  }

  return (
    <Container horizontalSpaced>
      <ModuleContainer>
        <IconContainer>
          <ModuleOnlineSvg size={64} workoff={!!workoff} online={!!online} />
        </IconContainer>
        <DescriptionContainer>
          <DescriptionLine>
            <ModuleTitle>{name}</ModuleTitle>
          </DescriptionLine>
          <DescriptionLine>
            <SimpleText color={theme.colors.textDark} size={14}>
              COD: <strong>{id}</strong>
            </SimpleText>
          </DescriptionLine>
          <DescriptionLine>
            <SimpleText color={theme.colors.textDark} size={14}>
              {workoff ? 'SEM REDE' : mac}
            </SimpleText>
          </DescriptionLine>
          <DescriptionLine>
            <SimpleText color={theme.colors.textDark} size={14}>
              {ip}
            </SimpleText>
          </DescriptionLine>
        </DescriptionContainer>
        <VersionContainer>
          <SimpleText color={theme.colors.textDark} size={14}>
            {version}
          </SimpleText>
          <SimpleText color={theme.colors.textDark} size={14}>
            {Math.floor(ping)} ms
          </SimpleText>
        </VersionContainer>
        <MoreContainer>
          <button type="button" onClick={handleClickMore}>
            <FiMoreVertical size={32} />
          </button>
        </MoreContainer>
      </ModuleContainer>
    </Container>
  )
}
