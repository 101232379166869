import styled from 'styled-components'

import { darken } from '~/helpers/colors'

export const FeatureItemContent = styled.div<{ bgColor?: string; color?: string }>`
  width: 142px;
  max-width: 100%;
  border: 0;
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.5) 1px 1px 2px;
  overflow: hidden;

  border-radius: ${({ theme }) => theme.rounded}px;
  padding: ${({ theme }) => theme.spacing.m}px;

  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  color: ${({ color }) => color || 'currentColor'};
  transition: all ease-in-out 0.25s;
  &:hover {
    transform: scale(1.03);
    background-color: ${({ bgColor }) => darken(bgColor || '#FFF')};
  }
`

export const FeatureItemConteiner = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0;
  margin: 0;
  padding: ${({ theme }) => theme.spacing.m}px;
  cursor: pointer;
`

export const FeatureContainer = styled.div<{ itemWidth: string }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
  border: 0;
  overflow: hidden;
  ${FeatureItemConteiner} {
    max-width: ${({ itemWidth }) => itemWidth};
    width: ${({ itemWidth }) => itemWidth};
  }
`

export const IconContent = styled.div`
  align-self: center;
  svg,
  img {
    margin: 0 auto;
    padding: 0;
    display: block;
  }
  border: 0;
`

export const Description = styled.div<{ size?: number }>`
  flex: 1;
  align-self: center;
  border: 0;
  max-width: 100%;
  font-size: ${({ size }) => size || 14}px;
  font-weight: normal;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.l}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
