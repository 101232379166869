import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import { IconClockSvg } from '~/components/icons/IconClockSvg'
import { IconDeviceSvg } from '~/components/icons/IconDeviceSvg'
import { IconTimerSvg } from '~/components/icons/IconTimerSvg'
import { VariantColorsTypes } from '~/components/shared/core/src'
import { useAppTheme } from '~/components/shared/web/src'

import { FeatureItemConteiner, FeatureItemContent, Description, IconContent } from './styles'

const iconsNames = {
  device: IconDeviceSvg,
  clock: IconClockSvg,
  timer: IconTimerSvg
}

type FeatureItemProps = {
  iconName?: keyof typeof iconsNames
  title?: string
  themeColor?: VariantColorsTypes
  redirect?: string
}

export const FeatureItem: React.FC<FeatureItemProps> = ({
  title,
  iconName = 'device',
  themeColor = 'primary',
  redirect
}) => {
  const { theme, matchingBackgroudText } = useAppTheme()
  const Icon = iconsNames[iconName]
  const color = matchingBackgroudText(themeColor)
  const { push } = useHistory()

  const handleRedirect = useCallback(() => {
    if (redirect) push(redirect)
  }, [redirect, push])

  return (
    <FeatureItemConteiner onClick={handleRedirect}>
      <FeatureItemContent bgColor={theme.colors[themeColor]} color={color}>
        <IconContent>
          <Icon size={64} color={color} />
        </IconContent>
        <Description>
          <span>{title}</span>
        </Description>
      </FeatureItemContent>
    </FeatureItemConteiner>
  )
}
