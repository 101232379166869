import React, { useState } from 'react'
import { MdMenu } from 'react-icons/md'

import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import styled from 'styled-components'

import { Navigation } from '~/components/Navigation'

import { isDefined } from '~/helpers/variables'
import { useAppAuth } from '~/hooks/useAppAuth'

const DrawerMenu = styled(Drawer)`
  min-width: 200px;
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }
`

const HeaderToolsContainer = styled.div``

const canLevels = [8, 9]
export const HeaderTools: React.FC = ({ children }) => {
  const { appAuth } = useAppAuth()
  const [open, setOpen] = useState(false)

  const toggleMenu = (isOpen?: boolean) => {
    if (isDefined(isOpen)) setOpen(isOpen)
    else setOpen(o => !o)
  }

  return (
    <>
      <HeaderToolsContainer>
        {children}
        {canLevels.includes(appAuth?.level) ? (
          <IconButton onClick={() => toggleMenu()}>
            <MdMenu color="#fff" />
          </IconButton>
        ) : null}
      </HeaderToolsContainer>
      <DrawerMenu open={open} onClose={() => toggleMenu(false)}>
        <Navigation toggleMenu={toggleMenu} />
      </DrawerMenu>
    </>
  )
}
