import React, { useCallback, useState } from 'react'

import { ThreeWayChipProps, ThreeWayChip } from '~/components/ThreeWayChip'

type Mathches = ThreeWayChipProps['value']

const matchValues: Mathches[] = ['null', 'yes', 'no']

function findNext(value: ThreeWayChipProps['value']) {
  const index = matchValues.findIndex(f => f === value) + 1
  if (index > matchValues.length - 1) return matchValues[0]
  if (index < 0) return matchValues[matchValues.length - 1]
  return matchValues[index]
}

// function indexValue(value: ThreeWayChipProps['value']): number {
//   return matchValues.findIndex(f => f === value)
// }
export type ItemModuleFilterProps = {
  id: string
  label: string
  value?: ThreeWayChipProps['value']
  onChange?: (id: string, value: ThreeWayChipProps['value']) => void
}
export const ItemFilter: React.FC<ItemModuleFilterProps> = ({ id, label, value = 'null', onChange }) => {
  const [localValue, setLocalValue] = useState<ThreeWayChipProps['value']>(value)

  const emitChange = useCallback(
    (id: string, value: ThreeWayChipProps['value']) => {
      if (onChange) onChange(id, value)
    },
    [onChange]
  )

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const value = (event?.currentTarget?.value || 'null') as ThreeWayChipProps['value']
      const found = findNext(value)
      setLocalValue(found)
      emitChange(id, found)
    },
    [emitChange, id]
  )

  return <ThreeWayChip label={label} value={localValue} onClick={handleClick} />
}
