import styled, { css } from 'styled-components'

const flexCenter = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ModuleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.rounded}px;
  margin-bottom: ${({ theme }) => theme.spacing.l}px;
  box-shadow: ${({ theme }) => theme.colors.shadow} 3px 3px 0;
  overflow: hidden;
`

export const IconContainer = styled.div`
  width: 72px;
  height: 72px;
  border: 0;
  margin-right: ${({ theme }) => theme.spacing.l}px;
  ${flexCenter}
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing.l}px;
`
export const DescriptionLine = styled.div`
  display: block;
  max-width: 100%;
  max-height: 20px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  word-break: break-all;
`

export const ModuleTitle = styled.span<{ color?: string; size?: number }>`
  display: block;
  width: 100%;
  max-width: 100%;

  font-size: ${({ size = 16 }) => size}px;
  line-height: ${({ size = 18 }) => size}px;
  padding: 0;
  margin: 0;
  color: ${({ theme, color }) => color || theme.colors.primary};
`
export const VersionContainer = styled.div`
  min-width: 72px;
  margin-right: ${({ theme }) => theme.spacing.l}px;
  ${flexCenter}
`
export const MoreContainer = styled.div`
  ${flexCenter}
  min-width: 32px;
  button {
    background-color: transparent;
    padding: 0;
    margin: 0 auto;
    border: 0;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`
