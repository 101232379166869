import React from 'react'

import { AppThemeProvider } from '@shared-web/index'

import GlobalStyle from './GlobalStyle'

export const AppTheme: React.FC = ({ children }) => {
  return (
    <AppThemeProvider>
      <GlobalStyle />
      {children}
    </AppThemeProvider>
  )
}
