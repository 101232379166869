import React, { useCallback } from 'react'

import { SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'

type SimpleFormProps = {
  initialData?: any
  onSubmit?: (formData: any) => void
}
export const SimpleForm: React.FC<SimpleFormProps> = ({ children, initialData, onSubmit }) => {
  // ...
  const handleSibmit: SubmitHandler = useCallback(
    formData => {
      if (onSubmit) onSubmit(formData)
    },
    [onSubmit]
  )

  return (
    <Form initialData={initialData} onSubmit={handleSibmit}>
      {children}
    </Form>
  )
}
