import { useDispatch } from 'react-redux'

import { configureStore, ThunkAction } from '@reduxjs/toolkit'
import type { Action, Middleware } from 'redux'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { appName } from '~/config'

import { rootReducer } from './reducers/rootReducer'

const persistConfig = {
  key: `store-${appName}`,
  storage,
  whitelist: ['auth', 'user']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]

const middlewares: Middleware[] = []

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: { ignoredActions, warnAfter: 300 },
      immutableCheck: { warnAfter: 300 } // corrige console.warn (default = 32ms)
    }).concat(...middlewares)
})

const persistor = persistStore(store)

// exporta types para usar direto no app
export type AppStore = typeof store
export type ApplicationState = ReturnType<typeof store.getState>
export type AppThunk = ThunkAction<void, ApplicationState, null, Action<string>>
export type AppDispatchType = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatchType>()

export { store, persistor }
