import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ptBr from 'date-fns/locale/pt-BR'
import { PersistGate } from 'redux-persist/integration/react'

import { AppTheme } from './components/AppTheme'
import { Routes } from './routes'
import { history } from './services/history'
import { store, persistor } from './store'

import 'react-toastify/dist/ReactToastify.min.css'
import '~/assets/css/App.css'

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppTheme>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBr}>
            <BrowserRouter>
              <Router history={history}>
                <Routes />
              </Router>
            </BrowserRouter>
            <ToastContainer />
          </LocalizationProvider>
        </AppTheme>
      </PersistGate>
    </Provider>
  )
}

export default App
