import React from 'react'

import { IconModuleSvg } from '~/components/icons/IconModuleSvg'

import { Button } from './styles'

export const iconsNames = {
  module: IconModuleSvg
}

export type ToolSvgButtonProps = {
  iconName: keyof typeof iconsNames
  size?: number
  disabled?: boolean
  color?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const ToolSvgButton: React.FC<ToolSvgButtonProps> = ({ iconName, size = 32, disabled, color, onClick }) => {
  const Icon = iconsNames[iconName]
  return (
    <Button size={size} disabled={!!disabled} color={color} onClick={onClick}>
      <Icon size={size} />
    </Button>
  )
}
