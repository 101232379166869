import React from 'react'

import { CustomIconSvgProps, svgProps } from './types'

type IconModuleSvgProps = CustomIconSvgProps & {}

export const IconModuleSvg: React.FC<IconModuleSvgProps> = ({ size = 72, color = 'currentColor' }) => {
  return (
    <svg {...svgProps} viewBox="0 0 3.81 3.81" width={`${size}px`} height={`${size}px`}>
      <g id="group">
        <rect fill="none" width="3.81" height="3.81" />
        <path
          id="module"
          fill={color}
          d="M1.49 2.27l0.26 0c0.02,0 0.03,0.02 0.03,0.04l0 0.26c0,0.02 -0.01,0.03 -0.03,0.03l-0.26 0c-0.02,0 -0.04,-0.01 -0.04,-0.03l0 -0.26c0,-0.02 0.02,-0.04 0.04,-0.04zm-0.57 0l0.25 0c0.02,0 0.04,0.02 0.04,0.04l0 0.26c0,0.02 -0.02,0.03 -0.04,0.03l-0.25 0c-0.02,0 -0.04,-0.01 -0.04,-0.03l0 -0.26c0,-0.02 0.02,-0.04 0.04,-0.04zm1.14 0l0.26 0c0.02,0 0.03,0.02 0.03,0.04l0 0.26c0,0.02 -0.01,0.03 -0.03,0.03l-0.26 0c-0.02,0 -0.03,-0.01 -0.03,-0.03l0 -0.26c0,-0.02 0.01,-0.04 0.03,-0.04zm0.58 0l0.25 0c0.02,0 0.04,0.02 0.04,0.04l0 0.26c0,0.02 -0.02,0.03 -0.04,0.03l-0.25 0c-0.02,0 -0.04,-0.01 -0.04,-0.03l0 -0.26c0,-0.02 0.02,-0.04 0.04,-0.04zm-1.7 -1l0 0c0.07,0 0.13,0.06 0.13,0.13l0 0c0,0.07 -0.06,0.13 -0.13,0.13l0 0c-0.07,0 -0.13,-0.06 -0.13,-0.13l0 0c0,-0.07 0.06,-0.13 0.13,-0.13zm0.36 -0.05l1.55 0c0.04,0 0.07,0.03 0.07,0.06l0 0.62c0,0.03 -0.03,0.06 -0.07,0.06l-1.55 0c-0.03,0 -0.06,-0.03 -0.06,-0.06l0 -0.62c0,-0.03 0.03,-0.06 0.06,-0.06zm-0.51 -0.26l2.23 0c0.08,0 0.15,0.07 0.15,0.16 0,0.53 0,1.05 0,1.58 0,0.08 -0.06,0.15 -0.14,0.15 -0.75,0 -1.49,0 -2.24,0 -0.08,0 -0.16,-0.07 -0.16,-0.16l0 -1.57c0,-0.09 0.08,-0.16 0.16,-0.16z"
        />
      </g>
    </svg>
  )
}
