import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CircleLoading } from '~/components/CircleLoading'
import { Container } from '~/components/layout/Container'
import { BufferedChat } from '~/components/Modules/BufferedChart'
import { FeatureItem } from '~/components/Modules/FeatureReport/FeatureItem'
import { FeatureList } from '~/components/Modules/FeatureReport/FeatureList'
import { PageTitle } from '~/components/PageTitle'
import { ToolBar } from '~/components/ToolBar'
import { ToolButton } from '~/components/ToolBar/ToolButton'

import { useIsMounted } from '~/hooks/useIsMounted'
import Api from '~/services/Api'
import { EventTypeId } from '~/services/Api/events'
import type { IGuarita, IResponseGuarita } from '~/services/Api/guaritas'

type PageParams = { id: string }

export const Module: React.FC = () => {
  const params = useParams<PageParams>()
  const isMounted = useIsMounted()
  const moduleId = parseInt(params?.id, 10) || 0

  const [moduleData, setModuleData] = useState<IGuarita>()
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(async () => {
    setLoading(true)
    const url = `/guaritas/${moduleId}`
    const response = await Api.getDefault<IResponseGuarita>(url)
    if (isMounted.current) {
      setLoading(false)
      if (response?.success) {
        setModuleData(response.guarita)
      }
    }
  }, [isMounted, moduleId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <div>
      <Container horizontalSpaced>
        {moduleData ? (
          <>
            <PageTitle spotlight={moduleData.name} title="" back>
              <ToolBar justify="flex-end">
                <ToolButton iconName="edit" />
                <ToolButton iconName="trash" disabled />
              </ToolBar>
            </PageTitle>
            <BufferedChat moduleId={moduleId} workoff={!!moduleData?.workoff} online={!!moduleData?.online} />
            <PageTitle spotlight={'Relat\u00f3rios'} title="prontos" />
            <FeatureList>
              <FeatureItem
                redirect={`/events/${moduleId}?eventType=${EventTypeId.DEVICE}`}
                title="Dispositivos"
                iconName="device"
              />
              <FeatureItem
                redirect={`/events/${moduleId}?eventType=${EventTypeId.MAKEUP}`}
                title="Acorda porteiro"
                iconName="clock"
              />
              <FeatureItem redirect={`/events/${moduleId}`} title="Eventos em Tempo Real" iconName="timer" />
            </FeatureList>
          </>
        ) : null}
      </Container>
      {loading ? <CircleLoading /> : null}
    </div>
  )
}
