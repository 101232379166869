import React from 'react'

import { CustomIconSvgProps, svgProps } from './types'

type IconClockSvgProps = CustomIconSvgProps & {}

export const IconClockSvg: React.FC<IconClockSvgProps> = ({ size = 72, color = 'currentColor' }) => {
  return (
    <svg {...svgProps} viewBox="0 0 99.08 99.08" width={`${size}px`} height={`${size}px`}>
      <g id="group">
        <rect id="squad" fill="none" width="99.08" height="99.08" />
        <g id="clock">
          <path
            fill={color}
            d="M49.66 21.32c17.73,0 32.11,14.38 32.11,32.11 0,17.73 -14.38,32.11 -32.11,32.11 -17.74,0 -32.11,-14.38 -32.11,-32.11 0,-17.73 14.37,-32.11 32.11,-32.11zm0 6.82c13.97,0 25.29,11.32 25.29,25.29 0,13.97 -11.32,25.29 -25.29,25.29 -13.97,0 -25.3,-11.32 -25.3,-25.29 0,-13.97 11.33,-25.29 25.3,-25.29z"
          />
          <rect fill={color} x="45.98" y="35.46" width="5.33" height="21.83" />
          <polygon fill={color} points="63.17,67.21 45.98,57.28 48.64,52.67 65.84,62.59 " />
          <polygon fill={color} points="85.3,27.54 68.88,13.54 64.46,18.73 80.88,32.73 " />
          <polygon fill={color} points="13.78,27.54 30.2,13.54 34.62,18.73 18.2,32.73 " />
        </g>
      </g>
    </svg>
  )
}
