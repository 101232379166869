import React, { useCallback, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CircleLoading } from '~/components/CircleLoading'
import { SimpleForm } from '~/components/Forms/Form'
import { InputButton } from '~/components/Forms/InputButton'
import { InputGroup } from '~/components/Forms/InputGroup'
import { InputIcon } from '~/components/Forms/InputIcon'
import { Field } from '~/components/Forms/styles'

import { useAppAuth } from '~/hooks/useAppAuth'
import { useAppSigned } from '~/hooks/useAppSigned'
import { useIsMounted } from '~/hooks/useIsMounted'
import { SigIn } from '~/services/Api'
import { LevelTypes } from '~/services/Api/users'
import { SignInDto } from '~/services/BaseApi/types'

export const FormLogin: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const isMounted = useIsMounted()
  const [signed, signedLevel, authorize] = useAppSigned()
  const { logout } = useAppAuth()

  const handleSubmit = useCallback(
    async (formData: SignInDto) => {
      setLoading(true)
      const response = await SigIn(formData)
      if (isMounted.current) {
        setLoading(false)
        if (response?.success) {
          authorize({
            token: response.token,
            refreshToken: response.refreshToken,
            level: response?.user?.level
          })
        } else {
          toast.error('Acesso não autorizado')
          logout()
        }
      }
    },
    [authorize, isMounted, logout]
  )

  if (signed) {
    if (signedLevel > LevelTypes.SINDICO) {
      return <Redirect to={'/search-serial'} />
    }
    return <Redirect to={'/modules'} />
  }

  return (
    <>
      <SimpleForm onSubmit={handleSubmit}>
        <InputIcon name="email" type="email" />
        <InputIcon name="password" type="password" />
        <InputGroup columns={2} fixed>
          <Field />
          <InputButton fullWidth bold type="submit" themeColor="secondary" title="ENVIAR" disabled={!!loading} />
        </InputGroup>
      </SimpleForm>
      {loading ? <CircleLoading /> : null}
    </>
  )
}
