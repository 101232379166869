import { toast } from 'react-toastify'

import { AxiosResponse } from 'axios'

import { store } from '~/store'

import { BaseApi } from '../BaseApi'
import type { SignInDto, ResponseApi, IResponseRefreshToken } from '../BaseApi/types'
export type { ResponseApi }

const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL || '' : 'http://localhost:3001'
const Api = new BaseApi(store, { enableRefreshToken: true, baseURL })

Api.Api.interceptors.response.use((res: AxiosResponse<ResponseApi>) => {
  if (res?.data?.message === 'Network Error') {
    toast.error('Aplicativo está offline', { autoClose: 5000 })
  }
  return res
})

export default Api

export function SigIn(payload: SignInDto): Promise<IResponseRefreshToken> {
  return Api.postDefault<IResponseRefreshToken>('auth', payload)
}

export interface IResponsePaginate<T> extends ResponseApi {
  pages: number
  page: number
  total: number
  data: T[]
}

export interface ISearchParams {
  search?: string
}

export type ParamsPagination = {
  page?: number
  size?: number
  orderBy?: string
  order?: string
}
