/* eslint-disable no-undef */
import React, { useCallback } from 'react'

import styled from 'styled-components'

const ChipContainer = styled.div<{ actived?: boolean }>`
  padding: ${({ theme }) => theme.spacing.s}px;
  display: inline-block;

  div {
    border-radius: ${({ theme }) => theme.rounded}px;
    padding: ${({ theme }) => theme.spacing.s}px;
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme, actived }) => (actived ? theme.colors.primary : theme.colors.textDark)};
    margin-bottom: ${({ theme }) => theme.spacing.m}px;
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
    cursor: pointer;
  }
`

interface Props<T = any> {
  title?: string
  Icon?: JSX.Element
  CloseIcon?: JSX.Element
  actived?: boolean
  onChange?: (value?: T | number) => void
  value?: T | number
}

export const SimpleChip: React.FC<Props> = ({ title, Icon, value, CloseIcon, actived, onChange }) => {
  const handleClick = useCallback(
    (isActived = false) => onChange && onChange(isActived ? value : undefined),
    [onChange, value]
  )

  return (
    <ChipContainer onClick={() => handleClick(!actived)} actived={!!actived}>
      <div>
        <span>{title}</span>
        {actived ? CloseIcon || null : Icon || null}
      </div>
    </ChipContainer>
  )
}
