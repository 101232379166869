import React, { useState, useCallback } from 'react'
import { CgClose } from 'react-icons/cg'

import { DeviceTypeId, deviceTypes } from '~/services/Api/devices'

import { useFilter } from '../Filter/FilterProvider'
import { SimpleChip } from '../Forms/SimpleChip'
import { IFilterEvent } from './EventFilter'

export const EventFilterDevices: React.FC = () => {
  const { updateFilter, filter } = useFilter<IFilterEvent>()
  const [deviceType, setDeviceType] = useState(filter?.deviceType)

  const handleChange = useCallback(
    (device?: number) => {
      const data: IFilterEvent = {}
      data.deviceType = !isNaN(parseInt(`${device}`)) ? parseInt(`${device}`) : device

      setDeviceType(device)
      updateFilter(data)
    },
    [updateFilter]
  )

  return (
    <div>
      {deviceTypes.map(({ id, friendly }) => {
        if (id === DeviceTypeId.NONE) return null
        return (
          <SimpleChip
            actived={!!(deviceType === id)}
            value={id}
            key={`device-${id}`}
            title={friendly}
            CloseIcon={<CgClose />}
            onChange={handleChange}
          />
        )
      })}
    </div>
  )
}
