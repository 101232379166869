import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { IAuthUser, listUserOptions, UserOption } from '~/services/Api/users'
import { ApplicationState } from '~/store'
import { loadSuccess } from '~/store/reducers/user'

export function useAppUser() {
  const appUser = useSelector<ApplicationState, IAuthUser>(state => state.user)
  const dispatch = useDispatch()

  const setAppUser = useCallback(
    (payload: Partial<IAuthUser>) => {
      dispatch(loadSuccess(payload))
    },
    [dispatch]
  )

  return { appUser, setAppUser }
}
export function useUserOptions(): [UserOption[], boolean, () => Promise<void>] {
  const [loading, setLoading] = useState(false)
  const [userOptions, setUserOptions] = useState([])

  const fetchUserOptions = useCallback(async () => {
    setLoading(true)
    const response = await listUserOptions()
    setLoading(false)
    if (response?.success) {
      setUserOptions(response?.users || [])
    }
  }, [])

  useEffect(() => {
    if (!loading && !userOptions?.length) fetchUserOptions()
  }, [fetchUserOptions, userOptions, loading])

  return [userOptions, loading, fetchUserOptions]
}
