import React, { useState, useCallback } from 'react'

import { ChipListContainer } from '~/components/ThreeWayChip'

import { ItemFilter, ItemModuleFilterProps } from './ItemFilter'
import { IModuleFilter, filterChips } from './types'
import { itemFilterValueDto, emitFilterValueDto, IModuleFilterItem } from './utils'

type ModuleFilterProps = {
  onFilter?: (data: Partial<IModuleFilter>) => void
  defaultFilter?: Partial<IModuleFilter>
}

export const ModuleFilter: React.FC<ModuleFilterProps> = ({ onFilter, defaultFilter = {} }) => {
  const [localFilter, setLocalFilter] = useState<IModuleFilterItem>()

  const emitChange = useCallback(
    (data: IModuleFilterItem) => {
      if (onFilter) onFilter(emitFilterValueDto(data))
    },
    [onFilter]
  )

  const onFilterChange = useCallback(
    (itemId: string, itemValue: ItemModuleFilterProps['value']) => {
      const currentFilter = { ...localFilter, [itemId]: itemValue } as IModuleFilterItem
      const newFilter = itemFilterValueDto(currentFilter)
      setLocalFilter(newFilter)
      emitChange(newFilter)
    },
    [localFilter, emitChange]
  )

  return (
    <ChipListContainer>
      {filterChips.map(item => {
        const itemValue = itemFilterValueDto(defaultFilter[item.id] || undefined)
        return <ItemFilter key={`filter-item-${item.id}`} {...item} value={itemValue} onChange={onFilterChange} />
      })}
    </ChipListContainer>
  )
}
