import type { IResponsePaginate, ISearchParams, ParamsPagination, ResponseApi } from '..'

import Api from '..'

import type { IGuarita } from '~/services/Api/guaritas'
import type { IAlert } from '~/services/Api/alerts'
import type { IUser, IUserContact } from '~/services/Api/users'
export interface IAlertItems {
  id: number
  alertId: number
  guaritaId: number
  userId: number
  deviceId: number
  type: number
  actived: boolean
  meta?: Record<string, any>
  alert?: Pick<IAlert, 'label' | 'meta' | 'actived' | 'realtime' | 'summary'>
  user?: Pick<IUser, 'name'>
  guarita?: Pick<IGuarita, 'name'>
  destination?: IUserContact[]
}
export interface IResponseSearchAlertItems extends ResponseApi {
  alertItems: IAlertItems[]
}

export interface IResponseOneAlertItem extends ResponseApi {
  alertItem: IAlertItems
}

export async function listAlertItems(params: ISearchParams) {
  const response = await Api.getDefault<IResponseSearchAlertItems>('/alerts/items', { params })
  return response
}
export interface IUpdateItemItemsParams {
  // actived?: boolean
  guarita?: IAlertItems['guarita']
  alert?: Partial<IAlert>
}
export async function updateAlertItems(id: number, data: IUpdateItemItemsParams): Promise<ResponseApi> {
  const response = await Api.patchDefault(`/alerts/items/${id}`, data)
  return response
}

export interface IFilterAlertItems {
  search?: string
}

export type ParamsPaginateAlertItems = Partial<ParamsPagination> & Partial<IFilterAlertItems>

export async function paginateAlertItems(params: ParamsPaginateAlertItems) {
  const response = await Api.getDefault<IResponsePaginate<IAlertItems>>('/alerts/items', { params })
  return response
}

export async function getAlertItem(id: number) {
  const response = await Api.getDefault<IResponseOneAlertItem>(`/alerts/items/${id}`)
  return response
}
