import React, { useRef, useState, useEffect } from 'react'

import { Header } from '~/components/Header'

import { useWindowDimensions } from '~/hooks/useWindowDimensions'

import { LayoutBar, LayoutContainer, LayoutContent, LayoutFooter } from './styles'

export const AppLayout: React.FC = ({ children }) => {
  const { height } = useWindowDimensions()
  const [maxHeight, setMaxH] = useState(`${height ? `${height}px` : 'auto'}`)
  const refBar = useRef<HTMLDivElement>(null)
  const refFoot = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (refBar.current && refFoot.current) {
      const bH = refBar.current.clientHeight
      const fH = refFoot.current.clientHeight
      setMaxH(`${height - bH - fH}px`)
    }
  }, [height])

  return (
    <LayoutContainer>
      <LayoutBar ref={refBar}>
        <Header />
      </LayoutBar>
      <LayoutContent id="layout-container" containerHeight={maxHeight}>
        {children}
      </LayoutContent>
      <LayoutFooter ref={refFoot}></LayoutFooter>
    </LayoutContainer>
  )
}
