import React from 'react'

import { VariantColorsTypes, useAppTheme } from '../shared/core/src'
import { CardContainer } from './styles'

type Props = {
  themeColor?: VariantColorsTypes
  spaced?: boolean
}

export const Card: React.FC<Props> = ({ children, spaced, themeColor = 'primary' }) => {
  const { theme, matchingBackgroudText } = useAppTheme()
  const color = matchingBackgroudText(themeColor)
  return (
    <CardContainer bgColor={theme.colors[themeColor]} color={color} spaced={spaced}>
      {children}
    </CardContainer>
  )
}
