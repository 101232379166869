import React, { useCallback, useMemo } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { format, parseISO, parse, add } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { LineChart, Line, Tooltip, Legend, XAxis, YAxis, Label, ReferenceLine } from 'recharts'

import { useAppTheme } from '~/components/shared/web/src'

import { medianInArray, compareValues } from '~/helpers/array'
import { alpha } from '~/helpers/colors'
import { round } from '~/helpers/number'
import { EventsEmittedDto } from '~/services/Api/guaritas'

function serverDateDto(date: string | Date): string {
  try {
    return date && format(parseISO(`${date}`), 'yyyy-MM-dd', { locale: ptBR })
  } catch (error) {
    return ''
  }
}

function toDate(date?: string) {
  if (!date) return new Date()
  return parse(date, 'yyyy-MM-dd', new Date())
}

function dateDto(date: string): string {
  try {
    const d = parse(date, 'yyyy-MM-dd', new Date())
    return date && format(d, 'dd/MM/yyyy', { locale: ptBR })
  } catch (error) {
    return ''
  }
}

function incDate(date: string): string {
  const d = parse(date, 'yyyy-MM-dd', new Date())
  const newDate = add(d, { days: 1 })
  return format(newDate, 'yyyy-MM-dd', { locale: ptBR })
}

type Props = {
  emittedEvents: EventsEmittedDto[]
}
export const Chart: React.FC<Props> = ({ emittedEvents = [] }) => {
  //   const isMounted = useIsMounted()
  const { theme } = useAppTheme()
  const { width, height, ref } = useResizeDetector({ refreshRate: 400, refreshMode: 'debounce' })

  const preparedValues = useMemo(() => {
    const prepared = emittedEvents
      .map(item => ({ ...item, date: serverDateDto(item.date) }))
      .sort(compareValues('date'))
    const firstDate = prepared.length && prepared[0].date

    let currentDate = `${firstDate}`
    const list = []
    if (firstDate) {
      while (toDate(currentDate) < toDate()) {
        const found = prepared.find(f => f.date === currentDate)
        list.push({ date: currentDate, count: found?.count })
        currentDate = incDate(currentDate)
      }
    }
    return list as EventsEmittedDto[]
  }, [emittedEvents])

  //   const [procData, setProcData] = useState<EventsEmittedDto[]>([])

  const valueFormatter = useCallback((value, name, p) => {
    if (name === 'count') return [value, 'eventos', p]
    return [round(value, 3), 'ping m\u00e9dio(ms) ', p]
  }, [])

  const dateFormatter = useCallback((date: string) => (date && dateDto(date)) || '--', [])

  const averageData = useMemo(() => medianInArray(preparedValues.map(({ count }) => count)) || 0, [preparedValues])

  //   const toAdd = useCallback(() => {
  //     if (procData.length < preparedValues.length && isMounted.current) {
  //       const newValue = preparedValues[procData.length]
  //       if (procData.length > 0) {
  //         const n = [...procData.map(p => ({ ...p })), newValue]
  //         setProcData(n)
  //       } else {
  //         setProcData([newValue])
  //       }
  //       //   console.log(newValue?.date)
  //       //   setProcData(old => [...old, newValue])
  //       setTimeout(() => toAdd(), 200)
  //     }
  //   }, [procData, preparedValues, isMounted])

  //   const startProc = useCallback(() => {
  //     if (!procData.length) setTimeout(() => toAdd(), 200)
  //   }, [procData, toAdd])

  //   useEffect(() => {
  //     if (!procData.length && preparedValues.length) {
  //       startProc()
  //     }
  //   }, [startProc, procData, preparedValues])

  //   console.log('procData', procData.length, procData)

  return (
    <div ref={ref} style={{ minHeight: '100%' }}>
      <LineChart width={width} height={height} data={preparedValues}>
        <XAxis dataKey="date" hide />
        <YAxis dataKey="count" hide yAxisId="left" />
        <Line
          type="linear"
          dataKey="count"
          stroke={theme.colors.secondary}
          strokeWidth={1}
          dot={{ r: 2 }}
          yAxisId="left"
        />
        {averageData ? (
          <ReferenceLine
            y={averageData}
            stroke={alpha(theme.colors.secondary)}
            strokeWidth={1}
            strokeDasharray="3 5"
            yAxisId="left"
          >
            <Label position="insideTopLeft" offset={6} fill={alpha(theme.colors.secondary)} fontSize={12}>
              {`m\u00e9dia: ${averageData}`}
            </Label>
          </ReferenceLine>
        ) : null}

        <Tooltip formatter={valueFormatter} labelStyle={{ color: '#000' }} labelFormatter={dateFormatter} />
        <Legend />
      </LineChart>
    </div>
  )
}
