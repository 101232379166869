import React, { useCallback } from 'react'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'

import { useAppSigned } from '~/hooks/useAppSigned'

import { IAppRoute } from './types'

type PrivateRouteProps = IAppRoute & {}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ path, exact, Layout, Page }) => {
  const [signed] = useAppSigned()

  const render = useCallback(
    (props: RouteComponentProps) => {
      if (signed) {
        return (
          <Layout {...props}>
            <Page />
          </Layout>
        )
      }
      return <Redirect to={'/'} />
    },
    [Layout, Page, signed]
  )
  return <Route path={path} exact={!!exact} render={render} />
}
