import React from 'react'

import { DeviceIcon } from '~/components/DeviceIcon'
import {
  FlatItem,
  FlatIconContainer,
  FlatDescriptionContainer,
  FlatDescriptionLine,
  FlatTitle
} from '~/components/FlatItem'
import { useAppTheme } from '~/components/shared/web/src'

import { toMask } from '~/helpers/string'
import { IDevice, VehicleModel, VehicleColorName } from '~/services/Api/devices'

type Props = IDevice & {
  animeTime?: number
}
export const FoundDeviceItem: React.FC<Props> = ({
  deviceType,
  serial = '',
  label,
  bloco,
  unidade,
  vehiclePlate,
  vehicle,
  vehicleColor,
  animeTime,
  guarita,
  guaritaId,
  guaritaBloco
}) => {
  const { theme } = useAppTheme()

  return (
    <FlatItem justify="flex-start" animeTime={animeTime}>
      <FlatIconContainer>
        <DeviceIcon deviceTypeId={deviceType} />
      </FlatIconContainer>
      <FlatDescriptionContainer grow={1}>
        <FlatDescriptionLine>
          <FlatTitle textColor={theme.colors.secondary}>
            <sup className="right">{guaritaId}</sup>
            {guarita?.name || 'Módulo indefinido'}
          </FlatTitle>
        </FlatDescriptionLine>
        <FlatDescriptionLine>{label}</FlatDescriptionLine>
        <FlatDescriptionLine size={10}>
          {guaritaBloco?.label || bloco} / {unidade}
        </FlatDescriptionLine>
        <FlatDescriptionLine size={10}>{serial}</FlatDescriptionLine>
      </FlatDescriptionContainer>
      <FlatDescriptionContainer>
        {vehicle && <FlatDescriptionLine>{VehicleModel[vehicle] || vehicle}</FlatDescriptionLine>}
        {vehicleColor && <FlatDescriptionLine>{VehicleColorName[vehicleColor] || vehicleColor}</FlatDescriptionLine>}
        {vehiclePlate && <FlatDescriptionLine>{toMask('XXX-XXXX', vehiclePlate)}</FlatDescriptionLine>}
      </FlatDescriptionContainer>
    </FlatItem>
  )
}
