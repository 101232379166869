import React, { memo } from 'react'

import { format } from 'date-fns'

import { IconEvent } from '~/components/icons/IconEvent'

import { IEvent } from '~/services/Api/events'

// import { IconClockSvg } from '../icons/IconClockSvg'
import { isDefined } from '../../helpers/variables'
import { getDeviceLabel, getEventLabel } from '../../services/helper'
import { Container } from '../layout/Container'
import { EventContent, EventContainer } from './styles'

interface Props extends IEvent {}

export const EventItem: React.FC<Props> = ({ date, deviceType, eventType }) => {
  const [toDate, toTime] = format(new Date(date), 'dd/MM/yyyy HH:mm:ss')?.split(' ') || [null, null]

  const title = isDefined(deviceType) ? getDeviceLabel(deviceType) : getEventLabel(eventType)

  return (
    <Container horizontalSpaced>
      <EventContainer>
        <EventContent>
          <IconEvent eventType={eventType} deviceType={deviceType} />
          <h3>{title}</h3>
        </EventContent>
        <div style={{ textAlign: 'right', paddingRight: 4 }}>
          {toDate}
          <br />
          {toTime}
        </div>
      </EventContainer>
    </Container>
  )
}

export const EventItemMemo = memo(EventItem)
