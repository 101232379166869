import React from 'react'

import { DeviceTypeId } from '~/services/Api/devices'
import { EventTypeId } from '~/services/Api/events'

import { svgProps, CustomIconSvgProps } from '../types'
import { pathEvents } from './pathEvents'

type Props = CustomIconSvgProps & {
  eventType: EventTypeId
  deviceType?: DeviceTypeId
}

export const IconEvent: React.FC<Props> = ({ size = 72, eventType, color = 'currentColor' }) => {
  const path = pathEvents[eventType] || ''
  return (
    <svg {...svgProps} viewBox="0 0 99.08 99.08" width={`${size}px`} height={`${size}px`}>
      <g id={`${eventType}_1432480652976`}>
        <rect fill="none" width="99.08" height="99.08" />
        <path fill={color} d={path} />
      </g>
    </svg>
  )
}
