import type { ResponseApi } from '../BaseApi/types'
import type { IGuarita } from './guaritas'

export enum DeviceTypeId {
  NONE = 0, // 0x00 --> NONE
  RF = 1, // 0x01 --> RF (Controle)
  TA = 2, // 0x02 --> TA (TAG Ativo)
  CT = 3, // 0x03 --> CT (CTW, CTWB, Cartão)
  RESERVED = 4, // 0x04 --> RESERVADO
  BM = 5, // 0x05 --> BM (Biometria)
  TP = 6, // 0x06 --> TP (TAG Passivo)
  SN = 7 // 0x07 --> SN (Senha)
}

export const deviceTypes = [
  { id: DeviceTypeId.NONE, label: 'none', friendly: '--' },
  { id: DeviceTypeId.RF, label: 'Receptor TX', friendly: 'Controle' },
  { id: DeviceTypeId.TA, label: 'Receptor TAG Ativo', friendly: 'TAG' },
  { id: DeviceTypeId.CT, label: 'Receptor CT/CTW', friendly: 'CT/CTW' },
  { id: DeviceTypeId.RESERVED, label: 'Reserved', friendly: 'Reservado' },
  { id: DeviceTypeId.BM, label: 'Receptor BM', friendly: 'Biometria' },
  { id: DeviceTypeId.TP, label: 'Receptor TP/UHF', friendly: 'TP/UHF' },
  { id: DeviceTypeId.SN, label: 'Receptor SN', friendly: 'Senha' }
] as const

export enum VehicleColorName {
  'AMARELO',
  'AZUL',
  'BEGE',
  'BRANCO',
  'CINZA',
  'DOURADO',
  'FANTAZIA',
  'GRENA',
  'LARANJA',
  'MARROM',
  'PRATA',
  'PRETO',
  'ROSA',
  'ROXO',
  'VERDE',
  'VERMELHO'
}

export enum VehicleModel {
  'AUDI',
  'BMW',
  'CHEVROLET',
  'CHRYSLER',
  'CITROEN',
  'FERRARI',
  'FIAT',
  'FORD',
  'GM',
  'HONDA',
  'HYUNDAI',
  'IMPORTADO',
  'JAGUAR',
  'JEEP',
  'KIA',
  'LAMBORGHINI',
  'LAND ROVER',
  'MAZDA',
  'MERCEDES',
  'MITSUBISHI',
  'MOTO',
  'NISSAN',
  'VEICULO',
  'PEUGEOT',
  'PORSCHE',
  'RENAULT',
  'SUBARU',
  'SUZUKI',
  'TOYOTA',
  'VOLKSWAGEN',
  'VOLVO',
  'SEM VEICULO',
  'FYBER',
  'SSANGYONG'
}

export interface IDevice {
  id: number

  guaritaId: number
  serial?: string
  // actionCount: number;
  deviceType: DeviceTypeId
  // dispDest: number;
  intserial: number
  digitalId: number
  idBio: number
  unidade: number
  bloco: number
  grupo: number
  label: string
  vehicle: number
  vehicleModel?: VehicleModel
  vehicleColor: number
  vehicleColorName?: VehicleColorName
  vehiclePlate: string
  createdAt: Date

  guarita?: Pick<IGuarita, 'name'>
  guaritaBloco?: {
    label: string
  }
}

export interface IResponseSearchDevices extends ResponseApi {
  devices: IDevice[]
}
