import React from 'react'

import styled from 'styled-components'

import { Field } from './styles'

const Group = styled.div<{ fixed?: boolean; itemWidth?: number }>`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: ${({ fixed }) => (fixed ? 'nowrap' : 'wrap')};
  margin-bottom: ${({ theme }) => theme.spacing.l}px;

  ${Field} {
    width: ${({ itemWidth }) => (itemWidth ? `${itemWidth}%` : 'auto')};
    padding: 0 ${({ theme }) => theme.spacing.l / 2}px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`

export type InputGroupProps = {
  fixed?: boolean
  columns?: number
}
export const InputGroup: React.FC<InputGroupProps> = ({ children, fixed, columns }) => {
  const itemWidth = columns ? 100 / columns : 0

  return (
    <Group fixed={!!fixed} itemWidth={itemWidth}>
      {children}
    </Group>
  )
}
