import React from 'react'

import { ThemeProvider, ThemeProviderProps } from '../../../core/src'
import { StyledProvider } from './StyledProvider'

export const AppThemeProvider: React.FC<ThemeProviderProps> = ({ children, theme }) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledProvider>{children}</StyledProvider>
    </ThemeProvider>
  )
}
