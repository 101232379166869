import React, { HTMLAttributes, useMemo } from 'react'

import styled from 'styled-components'

type Sizes = {
  width?: number
  height?: number
}
interface TextAreaProps extends HTMLAttributes<HTMLTextAreaElement> {
  children?: string
  sizes?: Sizes
}
const Text = styled.textarea<{ sizes?: Sizes }>`
  max-width: 100%;
  width: ${({ sizes }) => (sizes ? `${sizes.width}px` : '100%')};
  height: ${({ sizes }) => (sizes ? `${sizes.height}px` : 'auto')};
  display: block;
  resize: none;
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.white};
`
const TextArea: React.FC<TextAreaProps> = ({ children, sizes, ...rest }) => {
  const defaultValue = useMemo(() => {
    return typeof children === 'string' ? `${children}` : JSON.stringify(children)
  }, [children])

  return <Text defaultValue={defaultValue} {...rest} sizes={sizes} />
}

export default TextArea
